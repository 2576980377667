import { useEffect, useState } from 'react';
import VimSdkClient, { SdkConfigValidator } from '@getvim/utils-vim-sdk-client';
import logger from '../utils/logger';
import config from '../config';
import { assertType } from '../models/utils';
import { getApiKey } from '../api/tokensStorage';

function useSchedulingSdk(isApiKeyReady: boolean) {
  const [isSchedulingSdkInitialized, setIsSchedulingSdkInitialized] = useState(false);

  useEffect(() => {
    async function initSdk() {
      if (isApiKeyReady) {
        const sdkConfig = { sdkKey: getApiKey(), options: { env: config.BOOKING_SDK_ENV } };
        assertType(sdkConfig, SdkConfigValidator);
        setIsSchedulingSdkInitialized(await VimSdkClient.init(sdkConfig.sdkKey, sdkConfig.options));
      }
    }

    initSdk().catch((error) => logger.error(error));
  }, [isApiKeyReady]);

  return isSchedulingSdkInitialized;
}

export default useSchedulingSdk;
