import React, { FunctionComponent } from 'react';
import {
  Popover as BasePopover,
  PopoverDisclosure,
  PopoverStateReturn,
  PopoverArrow,
  usePopoverState,
} from 'reakit/Popover';
import classNames from 'classnames';

interface CustomPopoverProps {
  popover: PopoverStateReturn;
  withArrow?: boolean;
}

import './Popover.less';

const Popover: FunctionComponent<CustomPopoverProps & any> = ({
                                                                popover,
                                                                disclosure,
                                                                className,
                                                                children,
                                                                withArrow,
                                                                ...props
                                                              }) => (
  <>
    <PopoverDisclosure {...popover} ref={disclosure.ref} {...disclosure.props}>
      {(disclosureProps) => React.cloneElement(disclosure, disclosureProps)}
    </PopoverDisclosure>
    <BasePopover unstable_autoFocusOnHide={false} {...popover} {...props} className={classNames('reakit-popover', className)}>
      { withArrow && <PopoverArrow {...popover} className="popover-arrow"/> }
      {children}
    </BasePopover>
  </>
);

export { usePopoverState };

export default Popover;
