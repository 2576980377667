import React, { EventHandler } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

type ModalCloseButtonProps = {
  handleClose: EventHandler<React.MouseEvent>,
  className?: string,
};

const ModalCloseButton = ({ handleClose, className }: ModalCloseButtonProps) => (
  <button
    className={classNames('btn btn-transparent help-btn-x icon-x-2', className)}
    onClick={handleClose}
    aria-label="Close"
  />
);

ModalCloseButton.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ModalCloseButton;
