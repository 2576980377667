import SelectInput, {
  ReactSelectComponents,
  OptionProps,
  SelectComponentsType,
  SelectInputType,
} from '@getvim/components-atoms-select-input';
import Text, { ColorNameEnum, SizeEnum } from '@getvim/components-atoms-text';
import debounce from 'debounce';
import isEqual from 'lodash.isequal';
import React, { ComponentType, FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from '@getvim/translate';
import { InputActionMeta } from 'react-select';
import { ThemePropsType } from '@getvim/components-hooks-use-theme';
import {
  EventCreatorType,
  typeaheadEvent,
  TypeaheadEventType,
} from '@getvim/components-utils-analytics';
import partition from '@getvim/utils-partition';
import { GeoCoordinates } from '../models/Geocode';
import { FreeTextOption, FreeTextTerm, TypeaheadTypes } from '../models/FreeText';
import { freeText, getAllTaxonomies } from '../api';
import { Language } from '../models/Language';
import { ProviderType } from '../models/Provider';
import IconOrError from '../utils/iconOrError';
import { FreeTextResponse } from '../api/types';

type SpecialtySuggestionType = FreeTextResponse['data']['suggestions']['specialty'];
type ProviderSuggestionType = FreeTextResponse['data']['suggestions']['provider'];
export type SpecialtyOrProviderEnterEventType = (
  | SpecialtySuggestionType
  | ProviderSuggestionType
) & {
  type: TypeaheadTypes;
};
type Props = {
  onChange: (term: FreeTextTerm, analyticsEventCreator: EventCreatorType) => void;
  selectedValue: FreeTextTerm | undefined;
  geo?: GeoCoordinates;
  theme: ThemePropsType;
  userLanguage?: Language;
  isDisabled?: boolean;
  provider?: ProviderType;
  errorMessage?: string;
  inputRef?: any;
  onTypeaheadEntered?: (
    event: TypeaheadEventType<SpecialtyOrProviderEnterEventType>,
    eventCreator: EventCreatorType,
  ) => void;
};

const ReactSelectComponentsTyped = (ReactSelectComponents as unknown) as SelectComponentsType<
  FreeTextOption
>;
const ReactSelectOption = ReactSelectComponentsTyped.Option;

function fetchFreeText(text: string, geo?: GeoCoordinates, memberLanguage?: Language) {
  return freeText({ text, geo, memberLanguage }).then((result) => ({
    taxonomies: result.suggestions.specialty,
    physicians: result.suggestions.provider,
  }));
}

function ReactSelectWithTypeaheadIcon<T>(
  Component: ComponentType,
  theme: { mainColor: string; secondaryColor: string },
) {
  return (props: T) => {
    const {
      children,
      data: {
        value: { type },
      },
    } = props as any;
    const className = type === TypeaheadTypes.provider ? 'icon-user-doctor' : 'icon-stethoscope';
    return (
      <Component {...props}>
        <Text
          theme={theme}
          colorName={ColorNameEnum.themeSecondary}
          size={SizeEnum['16px']}
          inline
          className="margin-right-20 option-icon i-va-fix-2"
        >
          <i className={className} />
        </Text>
        {children}
      </Component>
    );
  };
}

let lastTerm = '';

const SpecialtiesNameInput: FunctionComponent<Props & Omit<SelectInputType, 'onChange'>> = ({
  onChange,
  selectedValue,
  theme,
  geo,
  userLanguage = 'en',
  isDisabled,
  provider,
  errorMessage,
  inputRef,
  onTypeaheadEntered,
}) => {
  const [options, setOptions] = useState<FreeTextOption[]>([]);
  const [defaultOptions, setDefaultOptions] = useState<FreeTextOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (provider) {
      setOptions([
        {
          label: `${provider.firstName} ${provider.lastName}`,
          value: { type: TypeaheadTypes.provider, value: [provider.npi] },
        },
      ]);
    }
  }, [provider]);

  useEffect(() => {
    if (provider) {
      return;
    }
    getAllTaxonomies({ memberLanguage: userLanguage }).then((taxonomies) => {
      const parsedTaxonomies = taxonomies.map((taxonomy) => ({
        label: taxonomy.name,
        value: {
          type: TypeaheadTypes.taxonomy,
          value: taxonomy.codes,
        },
      }));
      setOptions(parsedTaxonomies);
      setDefaultOptions(parsedTaxonomies);
    });
  }, [provider, userLanguage]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore sorry for future maintainers
  const OptionComponent = ReactSelectWithTypeaheadIcon<OptionProps<any>>(ReactSelectOption, theme);

  const onInputChange = (text: string, actionMeta: InputActionMeta) => {
    lastTerm = text;
    if (!text) {
      if (actionMeta.action === 'input-change') {
        setOptions(defaultOptions);
      }
    } else {
      setIsLoading(true);
      fetchFreeText(text, geo, userLanguage).then((freeTextResults) => {
        setIsLoading(false);
        if (lastTerm === text) {
          const parsedTaxonomies = freeTextResults.taxonomies.map((tax) => ({
            label: tax.searchTerm,
            value: {
              value: tax.filters.map((currFilter) => currFilter.value.taxonomyCode),
              type: TypeaheadTypes.taxonomy,
            },
          }));
          const parsedPhysicians = freeTextResults.physicians.map((phys: any) => ({
            label: `${phys.firstName} ${phys.lastName}`,
            value: { value: [phys.npi], type: TypeaheadTypes.provider },
          }));
          setOptions([...parsedTaxonomies, ...parsedPhysicians]);

          if (onTypeaheadEntered) {
            const results = [
              ...freeTextResults.taxonomies.map((currTaxonomy) => ({
                ...currTaxonomy,
                type: TypeaheadTypes.taxonomy,
              })),
              ...freeTextResults.physicians.map((currProvider) => ({
                ...currProvider,
                type: TypeaheadTypes.provider,
              })),
            ];
            onTypeaheadEntered(
              {
                term: text,
                type: 'taxonomy/provider',
                results,
              },
              (queryId, memberSessionId) => {
                return [
                  typeaheadEvent.contentEntered<SpecialtyOrProviderEnterEventType>({
                    queryId,
                    memberSessionId,
                    results,
                    type: 'taxonomy/provider',
                    term: text,
                    formatter: (data) => {
                      const [providers, taxonomies] = partition<SpecialtyOrProviderEnterEventType>(
                        data,
                        (currItem) => currItem.type === TypeaheadTypes.provider,
                      );
                      return {
                        physicians: (providers as ProviderSuggestionType).map(
                          ({ npi, firstName, lastName }) => {
                            return {
                              firstName,
                              lastName,
                              npi,
                            };
                          },
                        ),
                        taxonomies: ((taxonomies as unknown) as SpecialtySuggestionType).map(
                          (currRes) => ({
                            searchTerm: currRes.searchTerm,
                            codes: currRes.filters.map(
                              ({ value: { taxonomyCode } }) => taxonomyCode,
                            ),
                          }),
                        ),
                      };
                    },
                  }),
                ];
              },
            );
          }
        }
      });
    }
  };

  const intl = useIntl();

  return (
    <div className={`freetext-input-wrapper input left-inner-icon ${isDisabled ? 'disabled' : ''}`}>
      <IconOrError
        errorMessage={errorMessage}
        icon={
          selectedValue?.type === TypeaheadTypes.provider ? 'icon-user-doctor' : 'icon-stethoscope'
        }
      />
      <SelectInput
        name="freeText"
        inputId="test-specialty"
        errorMessage={errorMessage}
        placeholder={errorMessage || intl.formatMessage({ id: 'general.specialtyOrName' })}
        options={options}
        onChange={(option) => {
          if (option === null) setOptions(defaultOptions);

          const value = option?.[0];
          onChange(value?.value, (queryId, memberSessionId) => {
            const label = value?.label;
            const type = value?.value?.type;
            const data =
              type === TypeaheadTypes.provider ? value?.value?.value[0] : value?.value?.value;

            return [
              typeaheadEvent.clicked({
                searchTerm: label,
                selectedKey: type === TypeaheadTypes.provider ? 'npi' : 'codes',
                type,
                value: data,
                queryId,
                memberSessionId,
              }),
            ];
          });
        }}
        isDisabled={isDisabled}
        value={selectedValue}
        onInputChange={debounce(onInputChange)}
        isLoading={isLoading}
        // We don't want react-select to do the filtering, the backend does it
        filterOption={() => true}
        theme={theme}
        valueCompare={isEqual}
        customComponents={{
          Option: OptionComponent,
        }}
        inputRef={inputRef}
      />
    </div>
  );
};

export default SpecialtiesNameInput;
