/**
 * Created by chenrozenes on 08/06/2016.
 */
import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';

import './backButton.less';

const centeredButtonClassNames = 'btn-transparent centered-back-btn';
const centeredIconClassNames = 'centered-back-btn-icon icon-back-arrow i-va-fix-2';
const leftButtonClassNames = 'btn-modal-header-back pull-left';
const leftIconClassNames = 'icon-back-arrow';

const propTypes = {
  centered: PropTypes.bool,
  onBack: PropTypes.func,
  text: PropTypes.object,
  previousPageName: PropTypes.string,
  className: PropTypes.string,
};
type ModalBackButtonType = InferProps<typeof propTypes>;

const defaultProps: ModalBackButtonType = {
  onBack: undefined,
  text: null,
  centered: false,
  previousPageName: undefined,
  className: '',
};

class ModalBackButton extends React.PureComponent<ModalBackButtonType> {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  render() {
    const { centered, text, previousPageName, className, onBack } = this.props;
    const textToDisplay = text || centered ? `Back to ${previousPageName}` : ' ';
    return (
      <button
        className={classNames('btn', {
          [centeredButtonClassNames]: centered,
          [leftButtonClassNames]: !centered
        }, className)}
        onClick={onBack || undefined}
        aria-label="Back"
      >
        <i
          className={classNames({
            [centeredIconClassNames]: centered,
            [leftIconClassNames]: !centered
          })}
        />
        <span>
          {textToDisplay}
        </span>
      </button>
    );
  }
}

export default ModalBackButton;
