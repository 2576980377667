import React from 'react';
import { Route, Switch } from 'react-router-dom';
import useQueryString from '@getvim/components-hooks-use-query-string';
import { initAnalyticsReporter } from '@getvim/components-utils-analytics';
import SomethingWentWrongError from '../pages/errorPages/SomethingWentWrongError';
import Pages from '../models/pages';
import RouteWithAnalytics from './RouteWithAnalytics';
import config from '../config';
import useApiKeyPreferences from '../hooks/useApiKeyPreferences';
import App from '../App';

import 'bootstrap/less/bootstrap.less';
import '@getvim/components-base-css/src/styles/main.less';

export default () => {
  const { clientId } = useApiKeyPreferences();
  const { analyticsMetadata } = useQueryString();
  const metadata = analyticsMetadata as object;

  const analytics = initAnalyticsReporter({
    segmentApiKey: config.SEGMENT_API_KEY,
    appName: clientId,
    metadata,
  });

  return (
    <Switch>
      <RouteWithAnalytics path={Pages.ErrorPage.path} analytics={analytics}>
        <SomethingWentWrongError />
      </RouteWithAnalytics>
      <Route path="/">
        <App />
      </Route>
    </Switch>
  );
};
