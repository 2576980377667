import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
  ThemeContext,
  ThemeVariablesWrapper,
  themes as defaultThemes,
  ThemePropsType,
} from '@getvim/components-hooks-use-theme';
import Loader from '@getvim/components-atoms-loader';
import useApiKeyPreferences from '../hooks/useApiKeyPreferences';
import { getTheme } from '../api';

export default function ThemeProvider({ children }: PropsWithChildren<any>) {
  const { theme: themeName } = useApiKeyPreferences();
  const [isThemeLoaded, setIsThemeLoaded] = useState(false);
  const defaultTheme = defaultThemes[themeName as keyof typeof defaultThemes] || defaultThemes.vim;
  const [themeData, setThemeData] = useState<ThemePropsType>(defaultTheme);

  useEffect(() => {
    const getThemeData = async () => {
      let fetchedThemeData;

      if (themeName) {
        fetchedThemeData = await getTheme({
          themeName,
          errorCallback: async () => {
            setThemeData(defaultTheme);
          },
        });
      }

      setThemeData(fetchedThemeData || defaultTheme);
      setIsThemeLoaded(true);
    };

    if (!isThemeLoaded) {
      getThemeData();
    }
  }, [isThemeLoaded, themeName, defaultTheme]);

  ThemeContext(themeData);

  return isThemeLoaded ? (
    <ThemeVariablesWrapper theme={themeData}>{children}</ThemeVariablesWrapper>
  ) : (
    <Loader theme={themeData} />
  );
}
