import React, { FunctionComponent, useMemo } from 'react';
import Button, { BtnType, BtnWidth, BtnBgColor } from '@getvim/components-atoms-button-react-16';
import {
  CategoryEnum,
  CategorySelector,
  getTranslatedCategory,
} from '@getvim/components-molecules-button-selectors';
import Popover, { usePopoverState } from '@getvim/components-atoms-popover';
import withIntl, { WithIntlProps } from '@getvim/components-hocs-with-intl';
import classNames from 'classnames';
import { PopoverFilterType } from '../typesDef';
import messages from '../messages';
import es from '../translations/es.json';

import '../Filters.less';

export { CategoryEnum };

export type CategoryPopoverType = PopoverFilterType<CategoryEnum> & {
  btnType?: BtnType;
  btnWidth?: BtnWidth;
  btnBgColor?: BtnBgColor;
  leftIcon: JSX.Element;
};

const CategoryPopover: FunctionComponent<CategoryPopoverType & WithIntlProps> = ({
  theme,
  intl,
  value,
  onChange,
  btnType,
  btnWidth,
  btnBgColor,
  leftIcon,
}) => {
  const language = useMemo(() => intl.locale as 'es' | 'en', [intl]);
  const categoryPopover = usePopoverState({ placement: 'bottom-start' });

  return (
    <Popover
      popover={categoryPopover}
      className="category-filter-popover"
      aria-label="Category"
      disclosure={
        <div className="category-btn-wrapper">
          {leftIcon && <div className="input-left-icon category-icon">{leftIcon}</div>}
          <Button
            color="white"
            buttonType={btnType}
            width={btnWidth}
            theme={theme}
            bgColor={btnBgColor}
            className="category-btn"
            aria-label={intl.formatMessage(messages.categoryFilterSelected, {
              category: getTranslatedCategory(value, language),
            })}
          >
            {value === 'PROCEDURE'
              ? intl.formatMessage(messages.categoryButtonLabel)
              : getTranslatedCategory(value, language)}
          </Button>
        </div>
      }
    >
      <div className="popover-content">
        <CategorySelector
          language={language}
          value={value}
          onChange={(filterValue: CategoryEnum) => {
            onChange(filterValue);
            categoryPopover.hide();
          }}
          direction="column"
          theme={theme}
          buttonType="dropdownOption"
          color="extraDarkGray"
        />
      </div>
    </Popover>
  );
};

export default withIntl<CategoryPopoverType>({
  es,
})(CategoryPopover);
