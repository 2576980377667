import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from '@getvim/components-atoms-loader';
import './Button.less';

export type ButtonColor = 'gray' | 'darkGray' | 'green' | 'red' | 'orange' | 'white' | 'extraDarkGray';
const btnColor: { [x in ButtonColor]: string } = {
  gray: '#6E6E6E',
  darkGray: '#5C5C5C',
  extraDarkGray: '#32333C',
  green: '#278600',
  red: '#B12704',
  orange: '#B55A00',
  white: '#FFFFFF',
};

const btnType = {
  tiny: 'btn-tiny',
  small: 'btn-small',
  large: 'btn-big',
  huge: 'btn-huge',
  pill: 'btn-small btn-pill',
  link: 'btn-inline-link',
  outline: 'btn-outline',
  dropdown: 'btn-dropdown',
  dropdownOption: 'btn-dropdown-option',
};

const btnWidth = {
  default: '',
  block: 'btn-block',
  small: 'btn-min-100',
  medium: 'btn-min-300',
};

const btnBgColor = {
  default: 'default-color',
  gray: 'btn-grey',
  lightGray: 'btn-light-grey',
  white: 'btn-outline',
  red: 'btn-red',
  themedOutline: 'btn-outline-themed',
};

const themeProps = {
  buttonColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  linkColor: PropTypes.string.isRequired,
  mainColor: PropTypes.string.isRequired,
};

export const buttonPropTypes = {
  theme: PropTypes.shape(themeProps),
  text: PropTypes.string,
  buttonType: PropTypes.oneOf(Object.keys(btnType) as Array<keyof typeof btnType>),
  width: PropTypes.oneOf(Object.keys(btnWidth) as Array<keyof typeof btnWidth>),
  className: PropTypes.string,
  bgColor: PropTypes.oneOf(Object.keys(btnBgColor) as Array<keyof typeof btnBgColor>),
  multiLines: PropTypes.bool,
  underline: PropTypes.bool,
  isLoading: PropTypes.bool,
  selected: PropTypes.bool,
  color: PropTypes.oneOf(Object.keys(btnColor) as Array<keyof typeof btnColor>),
  disabled: PropTypes.bool,
  styleOverrides: PropTypes.shape({
    border: PropTypes.string,
    selected: PropTypes.object,
  }),
};


type htmlButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
export type ButtonProps = PropTypes.InferProps<typeof buttonPropTypes> & htmlButtonProps;

export type BtnType = ButtonProps['buttonType'];
export type BtnWidth = ButtonProps['width'];
export type BtnBgColor = ButtonProps['bgColor'];
export type btnColor = ButtonProps['color'];

const Button: FunctionComponent<ButtonProps> = ({
  text,
  buttonType,
  width,
  color,
  bgColor,
  multiLines,
  underline,
  isLoading,
  theme,
  className,
  selected,
  children,
  disabled,
  type,
  ...rest
}: ButtonProps) => {
  const isDisabled = isLoading || disabled;
  const inlineColorStyle = color ? { color: btnColor[color] } : {};
  const outlineStyle =
    buttonType === 'outline'
      ? {
          backgroundColor: undefined,
          color: 'var(--main-color)',
          borderColor: 'var(--main-color)',
        }
      : {};
  const dynamicStyle = {
    ...inlineColorStyle,
    ...outlineStyle,
  };
  const loaderColor = bgColor === 'white' ? 'grey' : 'white';
  return (
    <button
      type={type}
      className={classNames(
        'btnx',
        btnType[buttonType],
        btnWidth[width],
        btnBgColor[bgColor],
        { 'two-lines-btn': multiLines },
        { 'text-underline': underline },
        className,
      )}
      style={dynamicStyle}
      disabled={isDisabled}
      {...rest}
    >
      {isLoading && (
        <Loader
          type="dots"
          size="small"
          padding="none"
          color={loaderColor}
          theme={{ mainColor: theme.mainColor }}
        />
      )}
      {!isLoading && text}
      {!isLoading && children}
    </button>
  );
};

Button.propTypes = buttonPropTypes;
Button.defaultProps = {
  theme: undefined,
  text: '',
  buttonType: 'large',
  width: 'default',
  className: '',
  bgColor: 'default',
  multiLines: false,
  underline: false,
  isLoading: false,
  selected: false,
  disabled: false,
  type: 'button',
};

export default Button;
