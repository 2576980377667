import React, { FunctionComponent, useMemo } from 'react';
import Button, { BtnType, BtnWidth, BtnBgColor } from '@getvim/components-atoms-button-react-16';
import ButtonGroup from '@getvim/components-molecules-button-group';
import Popover, { usePopoverState } from '@getvim/components-atoms-popover';
import { PopoverFilterType } from '../typesDef';

import '../Filters.less';

export type FamilyPhysicianPopoverType = PopoverFilterType<string> & {
  btnType?: BtnType;
  btnWidth?: BtnWidth;
  btnBgColor?: BtnBgColor;
  items: any[];
};

const FamilyPhysicianPopover: FunctionComponent<FamilyPhysicianPopoverType> = ({
  theme,
  value,
  onChange,
  btnType,
  btnWidth,
  btnBgColor,
  items,
}) => {
  const familyPhysicianPopover = usePopoverState({ placement: 'bottom-start' });

  const getTextFromValue = (value: string): string => {
    return items.find(el => el.value === value)?.text;
  };

  return (
    <Popover
      popover={familyPhysicianPopover}
      className="gender-filter-popover"
      aria-label="Family Physician"
      disclosure={
        <Button
          color="white"
          buttonType={btnType}
          width={btnWidth}
          theme={theme}
          bgColor={btnBgColor}
          className="gender-btn"
          aria-label={getTextFromValue(value)}
        >
          {getTextFromValue(value)}
        </Button>
      }
    >
      <div className="popover-content">
        <ButtonGroup
          items={items}
          value={value}
          onChange={(filterValue: string) => {
            onChange(filterValue);
            familyPhysicianPopover.hide();
          }}
          direction="column"
          theme={theme}
          buttonType="dropdownOption"
          color="extraDarkGray"
        />
      </div>
    </Popover>
  );
};

export default FamilyPhysicianPopover;
