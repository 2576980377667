import { boolean, intersection, partial, type, TypeOf } from 'io-ts';
import { LanguageValidator, Language } from './Language';
import { GeoCoordinatesValidator } from './Geocode';
import { assertType } from './utils';

const allowedLanguages = ['en', 'es'];

function getLanguageFromObject(query: any): Language {
  if (query?.language && allowedLanguages.includes(query?.language)) {
    return query.language;
  }

  return 'en';
}

export function userFromObject(query: any, position?: Position): User {
  let geo = query?.geo
    ? { longitude: Number(query?.geo?.longitude), latitude: Number(query?.geo?.latitude) }
    : undefined;
  if (!geo && position) {
    const {
      coords: { longitude, latitude },
    } = position;
    geo = { longitude: Number(longitude), latitude: Number(latitude) };
  }

  const user = {
    ...query,
    language: getLanguageFromObject(query),
    geo,
    isBroker: query?.isBroker === 'true' || query?.isBroker === true,
  };
  assertType(user, UserValidator);
  return user;
}

export const UserValidator = intersection(
  [
    type({
      language: LanguageValidator,
    }),
    partial({
      geo: GeoCoordinatesValidator,
      isBroker: boolean,
    }),
  ],
  'userType',
);

export type User = TypeOf<typeof UserValidator>;

export type withUserProp = {
  user: User;
};
