import React, { FunctionComponent } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';

export enum SizeEnum {
  '12px' = 'text-12',
  '13px' = 'text-13',
  '14px' = 'text-14',
  '16px' = 'text-16',
  '18px' = 'text-18',
  '22px' = 'text-22',
  '24px' = 'text-24',
}

export enum ColorNameEnum {
  dark = 'text-dark',
  danger = 'text-danger',
  extraDark = 'text-extra-dark',
  ultraDark = 'text-ultra-dark',
  light = 'text-faded',
  theme = 'text-theme-main',
  themeSecondary = 'text-theme-secondary',
}

export enum AlignEnum {
  center = 'text-center',
  left = 'text-left',
  right = 'text-right',
}

export enum WeightEnum {
  light = 'text-light',
  medium = 'text-medium',
  semibold = 'text-semibold',
  bold = 'text-strong',
  black = 'text-black',
}

const TextProps = {
  align: PropTypes.oneOf(Object.values(AlignEnum)),
  className: PropTypes.string,
  colorName: PropTypes.oneOf(Object.values(ColorNameEnum)),
  ellipsis: PropTypes.bool,
  html: PropTypes.bool,
  inline: PropTypes.bool,
  text: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SizeEnum)),
  theme: PropTypes.shape({
    mainColor: PropTypes.string,
    secondaryColor: PropTypes.string,
  }),
  weight: PropTypes.oneOf(Object.values(WeightEnum)),
};

type TextPropsType = InferProps<typeof TextProps>;

const Text: FunctionComponent<TextPropsType> = ({
  text,
  children,
  size,
  colorName,
  theme,
  weight,
  align,
  inline,
  className,
  ellipsis,
  html,
  ...other
}) => {
  const textInline = inline ? 'inline-block' : undefined;
  const textEllipsis = ellipsis ? 'three-dots' : undefined;
  const innerHTML = html && { dangerouslySetInnerHTML: { __html: text! } };
  const divChildren = !html ? [text, children] : '';
  return (
    <div
      className={classNames(size, colorName, align, weight, textEllipsis, textInline, className)}
      // eslint-disable-next-line
      {...innerHTML}
      {...other}
    >
      {divChildren}
    </div>
  );
};

Text.propTypes = TextProps;
Text.defaultProps = {
  size: undefined,
  colorName: undefined,
  align: AlignEnum.center,
  weight: undefined,
  inline: false,
  ellipsis: false,
  className: '',
  text: '',
  html: false,
  theme: {
    mainColor: undefined,
    secondaryColor: undefined,
  },
};

export default Text;
