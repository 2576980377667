import { analyticsjs, AnalyticsInstance } from './typesHelper';

let analyticsInstance: AnalyticsInstance;
let initializedAppName: string;

/**
 * If analytics script isn't loaded for an network error, analytics loader acts as a queue for
 * events to be done, event though the events will never be done (because the script isn't loaded).
 * So, if the analytics wasn't loaded, we want to call the callback anyway to continue the
 * code flow
 * @param callback
 */
function callCallbackIfNotInitialized(callback?: () => void) {
  if (!analyticsInstance.isReady && callback) {
    callback();
  }
}

/**
 * @param apiKey - Segment.io api key.
 * @param appName
 */

export type AnalyticsReporterParams = {
  segmentApiKey: string;
  appName?: string;
  metadata?: object;
  skipPageCall?: boolean;
};

let metadata: object;

function initAnalyticsReporter(constructorParams: AnalyticsReporterParams) {
  const {
    segmentApiKey,
    appName,
    metadata: metadataFromConstructor,
    skipPageCall = false,
  } = constructorParams;
  if (!metadata && metadataFromConstructor) {
    metadata = metadataFromConstructor;
    if (analyticsInstance) {
      analyticsInstance.metadata = metadataFromConstructor;
    }
  }
  if (analyticsInstance) return analyticsInstance;
  const rawAnalytics = analyticsjs({ writeKey: segmentApiKey, skipPageCall });
  analyticsInstance = { ...rawAnalytics, isReady: false, metadata };

  analyticsInstance.ready(() => {
    analyticsInstance.isReady = true;
  });
  if (appName) initializedAppName = appName;

  analyticsInstance.track = (eventName, params?, options?, callback?) => {
    callCallbackIfNotInitialized(callback);
    rawAnalytics.track(
      eventName,
      { metadata, ...params },
      { context: { app: { name: initializedAppName } }, ...options, anonymousId: "f8699086-e9f1-4008-ae76-e786511410bd" },
      callback,
    );
  };

  analyticsInstance.page = (
    category?: string,
    name?: string,
    properties?: object,
    options?: SegmentAnalytics.SegmentOpts,
    callback?: () => void,
  ) => {
    callCallbackIfNotInitialized(callback);
    rawAnalytics.page(
      category,
      name,
      { metadata, ...properties },
      { context: { app: { name: initializedAppName } }, ...options },
      callback,
    );
  };

  analyticsInstance.identify = (userId: string, traits = {}, options?, callback?) => {
    callCallbackIfNotInitialized(callback);
    return rawAnalytics.identify(userId, { appName, ...traits }, options, callback);
  };
  analyticsInstance.metadata = metadata;

  return analyticsInstance;
}

export default initAnalyticsReporter;
