import { getSearchEvents } from '@getvim/components-utils-analytics';
import { FiltersType } from '../components/filters/typesDef';
import { GeoCode } from '../models/Geocode';

export type AnalyticsFilterType = FiltersType & { geo: Partial<GeoCode> };

const filterNameToEvent: { [key in keyof AnalyticsFilterType]: string } = {
  distance: 'distance filter click',
  gender: 'gender filter click',
  spokenLanguage: 'spoken language filter click',
  geo: 'geo filter click',
  offerTelemedicine: 'telemedicine filter click',
  sanitasPlus: 'sanitas plus filter click',
};

export default getSearchEvents<AnalyticsFilterType>(filterNameToEvent, ['geo']);
