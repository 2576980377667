import PropTypes, { InferProps } from 'prop-types';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Text, { SizeEnum, WeightEnum } from '@getvim/components-atoms-text';
import withIntl, { IntlType, WithIntlProps } from '@getvim/components-hocs-with-intl';
import { defineMessages } from 'react-intl';
import es from './translations/es.json';
import './DistanceSlider.less';

const messages = defineMessages({
  mile: {
    defaultMessage: 'mi',
    id: 'distanceSlider.milesSuffix'
  },
});

const propTypes = {
  className: PropTypes.string,
  overrideItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  ),
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    mainColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.number,
};

type SliderProps = InferProps<typeof propTypes>;

const milesString = (miles: number, intl: IntlType) => `${miles} ${intl.formatMessage(messages.mile)}`;

const DistanceSlider: FunctionComponent<SliderProps & WithIntlProps> = ({
  className,
  overrideItems,
  onChange,
  value,
  theme,
  intl,
}) => {
  const items = overrideItems || [
    {
      id: 'mi10',
      value: 10,
      text: milesString(10, intl),
    },
    {
      id: 'mi25',
      value: 25,
      text: milesString(25, intl),
    },
    {
      id: 'mi50',
      value: 50,
      text: milesString(50, intl),
    },
    {
      id: 'mi100',
      value: 100,
      text: milesString(100, intl),
    },
  ];

  return (
    <div
      className={classNames('distance-filter-slider', className)}
      role="radiogroup"
      aria-label="distance filter"
    >
      <div className="slider-line"/>
      {items?.map((item) => (
        <input
          type="radio"
          id={item.id}
          key={item.id}
          name="distance"
          className="slider-radio-btn"
          value={item.value}
          onClick={onChange}
          onChange={() => {
          }}
          checked={value === item.value}
        />
      ))}
      <div className="labels">
        {items?.map((item, index) => (
          <label htmlFor={item.id} className={`label-${index}`} key={item.id}>
            <Text weight={WeightEnum.semibold} size={SizeEnum['12px']} text={item.text} theme={theme}/>
          </label>
        ))}
      </div>
      <div className="slider-fill"/>
    </div>
  );
};

DistanceSlider.propTypes = propTypes;

DistanceSlider.defaultProps = {
  className: '',
  value: undefined,
  overrideItems: undefined,
};

export default withIntl<SliderProps>({ es })(DistanceSlider);
