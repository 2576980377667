import React, { PropsWithChildren, createContext, useState, useEffect, useContext } from 'react';
import { FeatureFlagContext, FeatureFlagsClient, FeatureFlagsClientProps } from './client';

const FFContext = createContext<FeatureFlagsClient | undefined>(undefined);

export function FeatureFlagProvider({
  namespace,
  rolloutKey,
  isCachingEnabled,
  onError,
  children,
}: PropsWithChildren<FeatureFlagsClientProps>) {
  const [client, setClient] = useState<FeatureFlagsClient>();

  useEffect(() => {
    const featureFlagsClientConfig = { namespace, rolloutKey, isCachingEnabled, onError };

    setClient(new FeatureFlagsClient(featureFlagsClientConfig));
  }, [setClient, namespace, rolloutKey, isCachingEnabled, onError]);

  return React.createElement(FFContext.Provider, { value: client }, children);
}

interface FeatureFlagOptions {
  context?: FeatureFlagContext;

  rawName?: boolean;
}

export default function useFeatureFlag<TValue extends boolean | string>(
  flag: string,
  defaultValue: TValue,
  { rawName, context }: FeatureFlagOptions = {},
): TValue {
  const [flagValue, setFlagValue] = useState<TValue>(defaultValue);
  const client = useContext(FFContext);

  useEffect(() => {
    if (client) {
      (async () => {
        setFlagValue(await client.getFlag(flag, defaultValue, rawName, context));
      })();
    }
  }, [client, setFlagValue]);

  return flagValue;
}

export function useFeatureFlagSync<TValue extends boolean | string>(
  flag: string,
  defaultValue: TValue,
  { rawName, context }: FeatureFlagOptions = {},
): TValue | undefined {
  const [flagValue, setFlagValue] = useState<TValue>();
  const client = useContext(FFContext);

  useEffect(() => {
    if (client) {
      (async () => {
        setFlagValue(await client.getFlag(flag, defaultValue, rawName, context));
      })();
    }
  }, [client, setFlagValue]);

  return flagValue;
}
