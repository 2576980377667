import React, { FunctionComponent } from 'react';
import Text, { ColorNameEnum, SizeEnum } from '@getvim/components-atoms-text';
import withIntl, { WithIntlProps } from '@getvim/components-hocs-with-intl';
import SvgVimLogo from '@getvim/components-atoms-svgs/build/vimLogo';
import messages from './messages';
import es from './translates/es.json';

import './PoweredByVim.less';

const PoweredByVim: FunctionComponent<WithIntlProps> = ({ intl }) => {
  return (
    <div className="powered-by-vim">
      <Text colorName={ColorNameEnum.dark} size={SizeEnum['12px']} className="badge-text">
        {intl.formatMessage(messages.powered)}
      </Text>
      <SvgVimLogo />
    </div>
  );
};

export default withIntl({ es })(PoweredByVim);
