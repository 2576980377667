import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './InputStyleWrapper.less';

export enum InputStyle {
  clean = 'clean',
  large = 'large',
  pill = 'pill',
}

export enum leftIconColorEnum {
  gray = 'gray',
}

const inputStyleWrapperProps = {
  className: PropTypes.string,
  inputStyle: PropTypes.oneOf(Object.values(InputStyle)),
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  leftIconColor: PropTypes.oneOf(Object.values(leftIconColorEnum)),
  disabled: PropTypes.bool,
  theme: PropTypes.shape({
    secondaryDarkColor: PropTypes.string,
    secondaryColor: PropTypes.string,
  }),
  hasError: PropTypes.bool,
};

type InputStyleWrapperPropsType = PropTypes.InferProps<typeof inputStyleWrapperProps>;

const InputStyleWrapper: React.FunctionComponent<InputStyleWrapperPropsType> = ({
  className,
  children,
  inputStyle,
  leftIcon,
  leftIconColor,
  rightIcon,
  disabled,
  theme,
  hasError,
}) => {
  return (
    <div
      className={classNames(
        'input-style-wrapper',
        { 'show-left-icon': leftIcon },
        { 'show-right-icon': rightIcon },
        { 'has-error': hasError },
        inputStyle,
        disabled,
        className,
      )}
    >
      {leftIcon && (
        <div className={classNames('input-left-icon', leftIconColor)}>
          {leftIcon}
        </div>
      )}
      <div className="inputs">{children}</div>
      {rightIcon && <div className="input-right-icon">{rightIcon}</div>}
    </div>
  );
};

InputStyleWrapper.propTypes = inputStyleWrapperProps;
InputStyleWrapper.defaultProps = {
  className: '',
  inputStyle: InputStyle.clean,
  rightIcon: undefined,
  leftIcon: undefined,
  leftIconColor: undefined,
  disabled: false,
  hasError: false,
  theme: undefined,
};

export default InputStyleWrapper;
