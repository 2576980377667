import { BaseSearchEvent, EventResult } from './analyticsTypes';

export const TypeaheadBeingInitiated = 'Typeahead being initiated';
export const TypeaheadContentEntered = 'Typeahead content entered';
export const TypeaheadClick = 'Typeahead click';
export const TypeaheadNoResults = 'Typeahead no results';
export const TypeaheadFailed = 'Typeahead failed';

export type TypeaheadEventType<T> = {
  type: string;
  term: string;
  results: T[];
  message?: string;
  preFabricated?: boolean;
};

export const typeaheadEvent = {
  clicked({
    searchTerm,
    type,
    value,
    memberSessionId,
    queryId,
    brokerId,
    selectedKey,
    preFabricated,
  }: BaseSearchEvent & {
    searchTerm: string | null;
    selectedKey: 'npi' | 'codes';
    type?: string;
    value: any;
    preFabricated?: boolean;
  }): EventResult {
    return {
      eventName: TypeaheadClick,
      params: {
        searchTerm,
        type,
        [selectedKey]: value,
        memberSessionId,
        queryId,
        brokerId,
        preFabricated,
      },
    };
  },
  contentEntered<T>({
    results,
    type,
    term,
    memberSessionId,
    queryId,
    message,
    formatter,
    brokerId,
    preFabricated,
  }: BaseSearchEvent &
    TypeaheadEventType<T> & { formatter: (data: T[]) => { [key: string]: any[] } }): EventResult {
    if (message) {
      return {
        eventName: TypeaheadContentEntered,
        params: {
          queryId,
          memberSessionId,
          term,
          message,
          brokerId,
          preFabricated,
        },
      };
    }
    if (results.length === 0) {
      return {
        eventName: TypeaheadNoResults,
        params: {
          queryId,
          memberSessionId,
          type,
          term,
          brokerId,
          preFabricated,
          ...formatter(results),
        },
      };
    }
    return {
      eventName: TypeaheadContentEntered,
      params: {
        queryId,
        memberSessionId,
        type,
        term,
        brokerId,
        preFabricated,
        ...formatter(results),
      },
    };
  },
  failed({
    queryId,
    memberSessionId,
    error,
    type,
    term,
    brokerId,
  }: BaseSearchEvent & { term: string; error?: any; type: string }): EventResult {
    return {
      eventName: TypeaheadFailed,
      params: {
        queryId,
        memberSessionId,
        errorMessage: error?.responseMessage,
        errorStatus: error?.status,
        type,
        term,
        brokerId,
      },
    };
  },
};
