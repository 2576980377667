import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { withAnalyticsProp, initAnalyticsReporter } from '@getvim/components-utils-analytics';
import { useTheme } from '@getvim/components-hooks-use-theme';
import useSdkEvents from '@getvim/components-hooks-use-sdk-events';
import { useChangeLocale, Locales } from '@getvim/translate';
import BaseLayout from '@getvim/components-atoms-base-layout';
import Header from '@getvim/components-atoms-header';
import Button from '@getvim/components-atoms-button';
import PoweredByVim from '@getvim/components-atoms-powered-by-vim';
import usePosition from '@getvim/components-hooks-use-position';
import useQueryString from '@getvim/components-hooks-use-query-string';
import { withUserProp, userFromObject } from './models/User';
import loadGooglePlacesAutoCompleteApi from './utils/googlePlaces';
import config from './config';
import logger from './utils/logger';
import { clearBrokerId } from './utils/brokerIdStorage';
import RouteWithAnalytics from './routes/RouteWithAnalytics';
import { MainSearchPageWithQs } from './pages/mainSearchPage/MainSearchPage';
import { ProviderDetailsFromQS } from './pages/providerDetailsPage/ProviderDetailsPage';
import LandingPage from './pages/landingPage/LandingPage';
import Pages from './models/pages';
import useApiKeyPreferences from './hooks/useApiKeyPreferences';

import 'bootstrap/less/bootstrap.less';
import '@getvim/components-base-css/src/styles/main.less';

import './App.less';

function App({
  user,
  memberToken,
  analytics,
}: withUserProp & withAnalyticsProp & { memberToken: string }) {
  const theme = useTheme();
  const { language } = user;
  const [isGoogleApiLoaded, setIsGoogleApiLoaded] = useState(false);
  const sdkEvents = useSdkEvents();
  const changeLocale = useChangeLocale();

  useEffect(() => {
    if (language) {
      loadGooglePlacesAutoCompleteApi(config.GOOGLE_API_KEY, language)
        .then(setIsGoogleApiLoaded)
        .catch((error) => logger.error(error));

      changeLocale(Locales[(language.toLocaleLowerCase() as Locales) || Locales.en]);
    }
  }, [language, changeLocale]);

  useEffect(() => {
    if (!user.isBroker) clearBrokerId();
  }, [user.isBroker]);

  return (
    <>
      <BaseLayout className="provider-discovery" scrollable>
        <Header
          className="provider-discovery-widget-header"
          leftElement={
            <Button
              onClick={sdkEvents.closed}
              className="close-dialog-btn"
              aria-label="close"
              buttonType="link"
              color="darkGray"
              theme={theme}
            >
              <i className="icon-x text-18 i-va-fix-3" />
            </Button>
          }
        />
        <Switch>
          <RouteWithAnalytics path={Pages.MainSearch.path} analytics={analytics}>
            <MainSearchPageWithQs
              user={user}
              memberToken={memberToken}
              isGoogleApiLoaded={isGoogleApiLoaded}
              analytics={analytics}
              onBook={sdkEvents.finished}
            />
          </RouteWithAnalytics>
          <RouteWithAnalytics path={Pages.ProviderDetails.path} analytics={analytics}>
            <ProviderDetailsFromQS
              user={user}
              memberToken={memberToken}
              isGoogleApiLoaded={isGoogleApiLoaded}
              analytics={analytics}
              onBook={sdkEvents.finished}
            />
          </RouteWithAnalytics>
          <RouteWithAnalytics path={Pages.Landing.path} analytics={analytics}>
            <LandingPage
              user={user}
              memberToken={memberToken}
              isGoogleApiLoaded={isGoogleApiLoaded}
              analytics={analytics}
            />
          </RouteWithAnalytics>
        </Switch>
      </BaseLayout>
      <PoweredByVim />
    </>
  );
}

export default () => {
  const { clientId } = useApiKeyPreferences();
  const { position } = usePosition();
  const [cssVariablesPolyfillLoaded, setCssVariablesPolyfillLoaded] = useState(false);
  const { user: userFromQS, analyticsMetadata, memberToken } = useQueryString();

  const metadata = analyticsMetadata as object;
  const [analytics, setAnalytics] = useState(
    initAnalyticsReporter({
      segmentApiKey: config.SEGMENT_API_KEY,
      appName: clientId,
      metadata,
    }),
  );

  useEffect(() => {
    const loadPolyfill = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        // check if we're on IE11
        if (window.MSInputMethodContext && document.documentMode) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          await import('ie11-custom-properties');
          logger.info(`Loaded IE11 CSS variables polyfill`);
        }
      } catch (e) {
        logger.error(`Failed to load IE11 CSS variables polyfill`, e);
      }
      setCssVariablesPolyfillLoaded(true);
    };
    loadPolyfill();
  }, []);

  useSdkEvents((payload) => {
    if (payload?.user) {
      const { analyticsMetadata: metadataFromSdk } = payload;
      setAnalytics(
        initAnalyticsReporter({
          segmentApiKey: config.SEGMENT_API_KEY,
          appName: clientId,
          metadata: metadataFromSdk,
        }),
      );
    }
  });

  const user = userFromObject(userFromQS, position);

  if (!cssVariablesPolyfillLoaded) {
    return null;
  }

  return <App user={user} memberToken={memberToken as string} analytics={analytics} />;
};
