import React from 'react';
import ErrorPage from '@getvim/components-organisms-error-page';
import { useIntl } from '@getvim/translate';
import useSdkEvents from '@getvim/components-hooks-use-sdk-events';
import { useTheme } from '@getvim/components-hooks-use-theme';
import './SomethingWentWrongError.less';

function SomethingWentWrongError() {
  const intl = useIntl();
  const sdkEvents = useSdkEvents();
  const theme = useTheme();

  return (
    <ErrorPage
      className="something-went-wrong-error"
      title={intl.formatMessage({ id: 'general.somethingWentWrong' })}
      description={intl.formatMessage({ id: 'pages.somethingWentWrong.errorDescription' })}
      buttonText={intl.formatMessage({ id: 'general.closeThisWindow' })}
      onClose={sdkEvents.closed}
      onSubmit={sdkEvents.closed}
      theme={theme}
    />
  );
}

export default SomethingWentWrongError;
