import React from 'react';
import { defineMessages } from 'react-intl';
import ButtonGroup, {
  ButtonGroupPropTypes,
  ButtonGroupProps,
} from '@getvim/components-molecules-button-group';
import withIntl, {
  WithIntlProps,
  IntlType,
  getIntlMapper,
} from '@getvim/components-hocs-with-intl';
import es from '../translations/es.json';

export type CategoryEnum = 'PROCEDURE' | 'CONDITION' | 'SPECIALTY' | 'PROVIDER';

const CategoryMessages = defineMessages({
  procedure: {
    defaultMessage: 'Service / Procedure',
    id: 'category.procedure',
  },
  condition: {
    defaultMessage: 'Condition',
    id: 'category.condition',
  },
  specialty: {
    defaultMessage: 'Speciality',
    id: 'category.specialty',
  },
  provider: {
    defaultMessage: 'Provider Name',
    id: 'category.provider',
  },
});

const categoryToMessage = {
  PROCEDURE: CategoryMessages.procedure,
  CONDITION: CategoryMessages.condition,
  SPECIALTY: CategoryMessages.specialty,
  PROVIDER: CategoryMessages.provider,
};

function getCategoryOptions(intl: IntlType) {
  return [
    {
      value: 'PROCEDURE',
      text: intl.formatMessage(categoryToMessage.PROCEDURE),
    },
    {
      value: 'CONDITION',
      text: intl.formatMessage(categoryToMessage.CONDITION),
    },
    {
      value: 'SPECIALTY',
      text: intl.formatMessage(categoryToMessage.SPECIALTY),
    },
    {
      value: 'PROVIDER',
      text: intl.formatMessage(categoryToMessage.PROVIDER),
    },
  ];
}

type CategorySelectorProps = Omit<ButtonGroupProps, 'items' | 'value'> & { value: string };

// eslint-disable-next-line no-unused-vars
const { items, ...PropTypes } = ButtonGroupPropTypes;

function CategorySelector({ intl, ...props }: CategorySelectorProps & WithIntlProps) {
  const categoryOptions = getCategoryOptions(intl);
  return <ButtonGroup items={categoryOptions} {...props} />;
}

CategorySelector.propTypes = PropTypes;

const intlMapper = getIntlMapper({ es });

export function getTranslatedCategory(
  category: 'PROCEDURE' | 'CONDITION' | 'SPECIALTY' | 'PROVIDER',
  language: 'en' | 'es',
) {
  const intl = intlMapper[language];
  const categoryMessage = categoryToMessage[category];
  return intl.formatMessage(categoryMessage);
}

export default withIntl<CategorySelectorProps>({ es }, intlMapper)(CategorySelector);
