import React from 'react';
import { createIntl, IntlShape, IntlConfig } from 'react-intl';

export type MessagesType = IntlConfig['messages'];

type MessageMapper = {
  en?: Record<string, string>,
  es?: Record<string, string>,
}

type IntlMapper = {
  en: IntlShape;
  es: IntlShape;
}

export interface WithIntlProps {
  intl: IntlShape;
}

export type IntlType = IntlShape;

export interface WithLanguageProps {
  language?: 'en' | 'es'
}

type Props = WithLanguageProps & WithIntlProps;

export function getIntlMapper(messageMapper: MessageMapper):any {
  return {
    es: createIntl({ locale: 'es', messages: messageMapper.es || {} }),
    en: createIntl({ locale: 'en', messages: messageMapper.en || {} }),
  };
}

function withIntl<P>(messageMapper: MessageMapper, intlMapper?: IntlMapper) {
  const mapLanguageToIntl = intlMapper || getIntlMapper(messageMapper);

  return (Component: React.ComponentType<P & Props>) => (props: P & Partial<Props>) => {
    const { intl: overrideIntl, language = 'en' } = props;
    const intl = overrideIntl || mapLanguageToIntl[language];

    return (
      <Component {...props} intl={intl}/>
    );
  };
}

export default withIntl;
