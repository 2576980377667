import React, { CSSProperties, FunctionComponent } from 'react';
import classNames from 'classnames';

const h1Size = {
  default: 'title-24',
  large: 'title-36',
};

const h1Margin = {
  default: 'margin-30',
  medium: 'md-margin',
  small: 'margin-10',
  none: 'no-margin',
};

const h1Align = {
  default: 'text-center',
  left: 'text-left',
  right: 'text-right',
};

type BrandedHeadingProps = {
  text: string,
  size?: keyof typeof h1Size,
  margin?: keyof typeof h1Margin,
  align?: keyof typeof h1Align,
  className?: string,
  theme: {
    mainColor: string,
    font: string,
    fontWeight: string,
  }
}

const BrandedH1: FunctionComponent<BrandedHeadingProps> = ({
  text,
  theme: { mainColor: color, font: fontFamily, fontWeight},
  size,
  margin,
  align,
  className,
  children
}) => {
  const actualSize = size || 'default';
  const actualMargin = margin || 'default';
  const actualAlign = align || 'default';
  const actualFontWeight = fontWeight as CSSProperties['fontWeight'];
  return (
    <h1
      className={classNames('title-brand-1', h1Size[actualSize], h1Margin[actualMargin], h1Align[actualAlign], className)}
      style={{ color, fontFamily, fontWeight: actualFontWeight }}
    >
      {children}
      {text}
    </h1>
  );
};

export default BrandedH1;