import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './BaseLayout.less';

type BaseLayoutPropsType = {
  children: [JSX.Element, JSX.Element] | [JSX.Element, JSX.Element, JSX.Element];
  scrollable?: boolean,
  className?: string;
};
type BaseLayoutComponent = FunctionComponent<BaseLayoutPropsType>;

const BaseLayout: BaseLayoutComponent = ({ children, className, scrollable }) => (
  <div className={classNames('base-layout', className, { 'un-scrollable': !scrollable })}>
    {children[0]}
    <div className="base-layout-content">{children[1]}</div>
    {children[2]}
  </div>
);

BaseLayout.propTypes = {
  className: PropTypes.string,
  scrollable: PropTypes.bool,
};

BaseLayout.defaultProps = {
  className: undefined,
  scrollable: true,
};

export default BaseLayout;
