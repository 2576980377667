import React, { FunctionComponent, InputHTMLAttributes } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import './CheckboxInput.less';

type CheckboxInputTypes = Omit<InputHTMLAttributes<HTMLInputElement>, 'value'>;

const CheckboxPropTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  label: PropTypes.string,
  errorText: PropTypes.string,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  disabled: PropTypes.bool,
  themedBg: PropTypes.bool,
  themedOutline: PropTypes.bool,
  theme: PropTypes.shape({
    mainColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
  }),
};

type CheckboxProps = Omit<InferProps<typeof CheckboxPropTypes>, 'onChange'> &
  Omit<CheckboxInputTypes, 'onChange'> & {
    onChange?: (currValue: boolean) => void;
  };

const CheckboxInput: FunctionComponent<CheckboxProps> = ({
  value,
  label,
  id,
  errorText,
  className,
  errorClassName,
  onChange,
  disabled,
  theme,
  themedBg,
  themedOutline,
  ...otherProps
}) => {
  const hasError = !!errorText;
  const themedBgStyle = themedBg
    ? { backgroundColor: theme ? theme.mainColor : undefined }
    : undefined;
  const themedOutlineStyle = themedOutline
    ? { borderColor: theme ? theme.secondaryColor : undefined }
    : undefined;
  const checkboxStyle = {
    ...themedBgStyle,
    ...themedOutlineStyle,
  };
  return (
    <div
      className={classNames('checkbox-container form-group material-form-group', className, {
        'has-error': hasError,
      })}
    >
      <input
        type="checkbox"
        className="css-checkbox"
        id={id}
        checked={!!value}
        disabled={disabled}
        onChange={({ target: { checked } }) => {
          if (onChange) onChange(checked);
        }}
        {...otherProps}
      />
      <label htmlFor={id}>{label}</label>
      <label style={checkboxStyle} className="css-label-checkbox web-checkbox" htmlFor={id} />
      {hasError ? (
        <span className={classNames('validation-message', errorClassName)}>{errorText}</span>
      ) : null}
    </div>
  );
};

CheckboxInput.propTypes = CheckboxPropTypes;
CheckboxInput.defaultProps = {
  value: undefined,
  onChange: undefined,
  label: undefined,
  errorText: undefined,
  className: undefined,
  errorClassName: undefined,
  disabled: false,
  theme: undefined,
  themedBg: false,
  themedOutline: false,
};

export default CheckboxInput;
