import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { AnalyticsInstance } from './typesHelper';
import initAnalyticsReporter, { AnalyticsReporterParams } from './initAnalyticsReporter';

const AnalyticsContext = createContext<AnalyticsInstance | undefined>(undefined);

export function AnalyticsProvider(props: PropsWithChildren<AnalyticsReporterParams>) {
  const [client, setClient] = useState<AnalyticsInstance>();

  useEffect(() => {
    setClient(initAnalyticsReporter(props));
  }, [setClient, props]);

  return <AnalyticsContext.Provider value={client}>{props.children}</AnalyticsContext.Provider>;
}

export function useAnalytics() {
  return useContext(AnalyticsContext);
}

