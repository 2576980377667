import React, { FunctionComponent, useMemo } from 'react';
import Button, { BtnType, BtnWidth, BtnBgColor } from '@getvim/components-atoms-button-react-16';
import {
  GenderEnum,
  GenderSelector,
  getTranslatedGender,
} from '@getvim/components-molecules-button-selectors';
import Popover, { usePopoverState } from '@getvim/components-atoms-popover';
import withIntl, { WithIntlProps } from '@getvim/components-hocs-with-intl';
import { PopoverFilterType } from '../typesDef';
import messages from '../messages';
import es from '../translations/es.json';

import '../Filters.less';

export { GenderEnum };

export type GenderPopoverType = PopoverFilterType<GenderEnum> & {
  btnType?: BtnType;
  btnWidth?: BtnWidth;
  btnBgColor?: BtnBgColor;
  displayAny?: boolean;
};

const GenderPopover: FunctionComponent<GenderPopoverType & WithIntlProps> = ({
  theme,
  intl,
  value,
  onChange,
  btnType,
  btnWidth,
  btnBgColor,
  displayAny = false,
}) => {
  const language = useMemo(() => intl.locale as 'es' | 'en', [intl]);
  const genderPopover = usePopoverState({ placement: 'bottom-start' });

  return (
    <Popover
      popover={genderPopover}
      className="gender-filter-popover"
      aria-label="Gender"
      disclosure={
        <Button
          color="white"
          buttonType={btnType}
          width={btnWidth}
          theme={theme}
          bgColor={btnBgColor}
          className="gender-btn"
          aria-label={intl.formatMessage(messages.genderFilterSelected, {
            gender: getTranslatedGender(value, language),
          })}
        >
          {value === 'ANY'
            ? displayAny
              ? intl.formatMessage(messages.any)
              : intl.formatMessage(messages.genderButtonLabel)
            : getTranslatedGender(value, language)}
        </Button>
      }
    >
      <div className="popover-content">
        <GenderSelector
          language={language}
          value={value}
          onChange={(filterValue: GenderEnum) => {
            onChange(filterValue);
            genderPopover.hide();
          }}
          direction="column"
          theme={theme}
          buttonType="dropdownOption"
          color="extraDarkGray"
        />
      </div>
    </Popover>
  );
};

export default withIntl<GenderPopoverType>({
  es,
})(GenderPopover);
