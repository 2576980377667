import React, { useContext, useMemo, PropsWithChildren, useState } from 'react';
import {
  createIntl,
  IntlShape,
  IntlProvider,
  IntlConfig,
  useIntl as useReactIntl,
  FormattedMessage as ReactFormattedMessage
} from 'react-intl';

export enum Locales {
  en = 'en',
  es = 'es',
}

type MessageMapper = {
  [key in Locales]?: Record<string, string>;
};

interface TranslationContext {
  rootTranslations?: {
    [key in Locales]: IntlShape;
  };
  changeLocale: (locale: Locales) => void;
}

const TranslateContext = React.createContext<TranslationContext>({ changeLocale: () => {} });

export const useIntl = useReactIntl;
export const FormattedMessage = ReactFormattedMessage;

export const TranslateProvider = (
  props: PropsWithChildren<
    Pick<IntlConfig, 'locale'> &
      Partial<IntlConfig> & {
        rootTranslations: { [key in Locales]: Record<string, string> };
      }
  >,
) => {
  const { rootTranslations, children, locale, ...intlProps } = props;
  const [actualLocale, changeLocale] = useState(locale);

  const messages = {
    ...rootTranslations.en,
    ...rootTranslations[actualLocale as Locales],
  };

  return React.createElement(
    IntlProvider,
    { ...intlProps, locale: actualLocale, messages },
    React.createElement(TranslateContext.Provider, { value: { changeLocale } }, children),
  );
};

function getIntlMapper(messageMapper: MessageMapper) {
  return {
    es: createIntl({ locale: 'es', messages: messageMapper.es || {} }),
    en: createIntl({ locale: 'en', messages: messageMapper.en || {} }),
  };
}

export function useTranslation(messageMapper?: MessageMapper) {
  const { locale } = useIntl();
  const { rootTranslations } = useContext(TranslateContext);

  let intlMapper = rootTranslations;

  if (messageMapper) {
    intlMapper = useMemo(() => getIntlMapper(messageMapper), [getIntlMapper, messageMapper]);
  }

  if (!intlMapper?.[locale as Locales]) {
    throw new Error(
      `Locale "${locale}" not supported or no translation mapper, make sure to pass 'messageMapper' with that locale`,
    );
  }

  return intlMapper[locale as Locales]!;
}

export function useChangeLocale() {
  return useContext(TranslateContext).changeLocale;
}
