import { useState, useEffect } from 'react';

export default function usePosition(
  watch?: boolean,
  options: PositionOptions = { enableHighAccuracy: true },
) {
  const [position, setPosition] = useState<Position>();
  const [positionError, setPositionError] = useState<Pick<PositionError, 'code' | 'message'>>();

  useEffect(() => {
    if (!navigator || !navigator.geolocation) {
      setPositionError({ code: 1, message: 'Geolocation is not supported' });
      return;
    }

    if (watch) {
      const watcher = navigator.geolocation.watchPosition(setPosition, setPositionError, options);
      return () => navigator.geolocation.clearWatch(watcher);
    }

    navigator.geolocation.getCurrentPosition(setPosition, setPositionError, options);
  }, [watch, options.enableHighAccuracy, options.maximumAge, options.timeout]);

  return { position, positionError };
}
