import React from 'react';
import { defineMessages } from 'react-intl';
import ButtonGroup, { ButtonGroupPropTypes, ButtonGroupProps } from '@getvim/components-molecules-button-group';
import withIntl, { WithIntlProps, IntlType, getIntlMapper } from '@getvim/components-hocs-with-intl';
import es from '../translations/es.json';

export type GenderEnum = 'MALE' | 'FEMALE' | 'ANY';

const GenderMessages = defineMessages({
  male: {
    defaultMessage: 'Male',
    id: 'gender.male'
  },
  female: {
    defaultMessage: 'Female',
    id: 'gender.female'
  },
  any: {
    defaultMessage: 'Any',
    id: 'gender.any'
  },
});

const genderToMessage = {
  MALE: GenderMessages.male,
  FEMALE: GenderMessages.female,
  ANY: GenderMessages.any,
};

function getGenderOptions(intl: IntlType) {
  return [
    {
      value: 'ANY',
      text: intl.formatMessage(genderToMessage.ANY),
    },
    {
      value: 'MALE',
      text: intl.formatMessage(genderToMessage.MALE),
    },
    {
      value: 'FEMALE',
      text: intl.formatMessage(genderToMessage.FEMALE),
    },
  ];
}

type GenderSelectorProps = Omit<ButtonGroupProps, 'items' | 'value'> & { value: string };


// eslint-disable-next-line no-unused-vars
const { items, ...PropTypes } = ButtonGroupPropTypes;

function GenderSelector({ intl, ...props }: GenderSelectorProps & WithIntlProps) {
  const genderOptions = getGenderOptions(intl);
  return (
    <ButtonGroup
      items={genderOptions}
      {...props}
    />
  );
}

GenderSelector.propTypes = PropTypes;

const intlMapper = getIntlMapper({ es });

export function getTranslatedGender(gender: 'MALE' | 'FEMALE' | 'ANY', language: 'en' | 'es') {
  const intl = intlMapper[language];
  const genderMessage = genderToMessage[gender];
  return intl.formatMessage(genderMessage);
}

export default withIntl<GenderSelectorProps>({ es }, intlMapper)(GenderSelector);
