import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import './clickableElement.less';

const ENTER_KEY = 13;
const SPACE_KEY = 32;

type ClickableDivProps = {
  clickHandler?: (args: any) => any,
  disabled?: boolean,
  className?: string,
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

// Intended for accessibility purposes
const ClickableDiv: FunctionComponent<ClickableDivProps> =
  ({ clickHandler, children, disabled=false, className, ...otherProps }) => {
    const onKeyDown = (e: React.KeyboardEvent) => {
      if (e.keyCode === ENTER_KEY || e.keyCode === SPACE_KEY) {
        clickHandler && clickHandler(e);
      }
    };

    return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={classNames('clickable-element', className)}
        onClick={!disabled ? clickHandler : undefined}
        onKeyDown={!disabled ? onKeyDown : undefined}
        role={!disabled ? 'button' : undefined}
        tabIndex={clickHandler ? (0) : (-1)}
        style={{ cursor: disabled ? 'auto' : 'pointer' }}
        {...otherProps}
      >
        {children}
      </div>
  );
};

export default ClickableDiv;
