import React, { PropsWithChildren, useState, useEffect } from 'react';
import { TranslateProvider, Locales } from '@getvim/translate';
import { FeatureFlagProvider } from '@getvim/components-feature-flags';
import useQueryString from '@getvim/components-hooks-use-query-string';
import Loader from '@getvim/components-atoms-loader';
import useSdkEvents, { initPreEventCallback } from '@getvim/components-hooks-use-sdk-events';
import AuthProvider from './AuthProvider';
import ThemeProvider from './ThemeProvider';
import translations from '../translations';
import config from '../config';
import {
  useApiKeyFromQSOnMount,
  getApiKey,
  saveApiKey,
  clearSessionId,
} from '../api/tokensStorage';
import useSchedulingSdk from '../hooks/useSchedulingSdk';
import { sendLogToBackend } from '../api/sendLogsToBackend';
import usePageNav from '../hooks/usePageNav';
import redirectToErrorPage from '../utils/redirectToErrorPage';
import { NonEmptyString } from '../models/utils';

export default function CoreProviders({ children }: PropsWithChildren<any>) {
  const navToPage = usePageNav();
  const [isApiKeyReady, setIsApiKeyReady] = useState(false);
  const { user, apiKey } = useQueryString();
  const language = (user as any)?.language;

  useApiKeyFromQSOnMount(setIsApiKeyReady);
  useSchedulingSdk(isApiKeyReady);

  initPreEventCallback({
    closed: () => clearSessionId(),
  });

  useEffect(() => {
    if (apiKey) {
      saveApiKey(apiKey as NonEmptyString);
    } else {
      const location = `AppWithUser`;
      sendLogToBackend({ location, apiKey });
    }
  }, [apiKey]);

  useSdkEvents((payload) => {
    const currApikey = getApiKey();

    if (!currApikey) {
      if (payload?.apiKey) {
        saveApiKey(payload.apiKey);
      } else {
        sendLogToBackend({ location: `App.tsx:AppWithUser:useSdkEvents`, payload, currApikey });
      }
    }

    if (payload?.user) {
      const { analyticsMetadata: metadataFromSdk } = payload;

      if (payload.user.language) {
        navToPage({
          page: 'Landing',
          params: {
            user: payload?.user,
            apiKey,
            analyticsMetadata: metadataFromSdk,
            memberToken: payload?.memberToken,
          },
        });
      } else {
        redirectToErrorPage(apiKey as any, metadataFromSdk as object);
      }
    }
  });

  return isApiKeyReady && (user as any)?.language ? (
    <TranslateProvider
      locale={Locales[(language?.toLocaleLowerCase() as Locales) || Locales.en]}
      rootTranslations={translations}
    >
      <AuthProvider>
        <FeatureFlagProvider rolloutKey={config.rollout__key}>
          <ThemeProvider>{children}</ThemeProvider>
        </FeatureFlagProvider>
      </AuthProvider>
    </TranslateProvider>
  ) : (
    <Loader />
  );
}
