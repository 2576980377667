import React, { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from '@getvim/translate';
import { useFormik } from 'formik';
import { v4 as uuid } from 'uuid';
import { union, undefined as undefinedType } from 'io-ts';
import useQueryString from '@getvim/components-hooks-use-query-string';
import Loader from '@getvim/components-atoms-loader';
import { useTheme } from '@getvim/components-hooks-use-theme';
import isEqual from 'lodash.isequal';
import {
  EventCreatorType,
  SearchActionEvents,
  withAnalyticsProp,
} from '@getvim/components-utils-analytics';
import { defaultFilterValues, defaultFormValues, FormDefTypes } from './formDef';
import { find } from '../../api';
import TopForm, { IsGoogleLoaded } from '../../components/topForm/TopForm';
import { Language, LanguageValidator } from '../../models/Language';
import { assertType, NonEmptyString, NonEmptyStringValidator } from '../../models/utils';
import { GeoCode, GeoCodeValidator } from '../../models/Geocode';
import { FreeTextTerm, FreeTextTermValidator, TypeaheadTypes } from '../../models/FreeText';
import { SearchResultsType } from '../../models/SearchResults';
import SearchResults from '../../components/searchResults/SearchResults';
import logger from '../../utils/logger';
import { OnBook } from '../../components/bookButtonBySdk/BookButtonBySdk';
import usePageNav from '../../hooks/usePageNav';
import FiltersMenu from '../../components/filters/FiltersMenu';
import { withUserProp } from '../../models/User';
import SearchEvents from '../../utils/searchEvents';
import { getSessionId } from '../../api/tokensStorage';
import { getBrokerId } from '../../utils/brokerIdStorage';

const resultsLimit = 5;

export type MainSearchPageProps = withAnalyticsProp &
  IsGoogleLoaded &
  OnBook & {
    brokerId?: NonEmptyString;
    memberToken?: string;
    freeText: FreeTextTerm;
    geo: Partial<GeoCode>;
    language: Language;
  };

function validateParams(params: { freeText?: any; geo?: any } = {}) {
  const { freeText, geo } = params;
  // Params here sometimes come from parent iframe or from query params, so we check them strictly
  return !!(
    freeText &&
    geo &&
    (geo.address || geo.zip || (geo.geocode && geo.geocode.latitude && geo.geocode.longitude))
  );
}

function checkIfPartialEqual(original: FormDefTypes, partial: Partial<FormDefTypes>): boolean {
  return Object.keys(partial).every((key) => {
    const currKey = key as keyof FormDefTypes;
    return isEqual(partial[currKey], original[currKey]);
  });
}

const MainSearchPage: FunctionComponent<MainSearchPageProps> = ({
  brokerId,
  memberToken,
  freeText,
  geo,
  language,
  isGoogleApiLoaded,
  onBook,
  analytics,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const navToPage = usePageNav();
  const [pageNumber, setPageNumber] = useState(1);
  const [isSearchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResultsType>({
    providers: [],
    total: 0,
  });
  const [enableReset, setEnableReset] = useState<boolean>(false);
  const [initialQueryId] = useState(() => uuid());
  const formik = useFormik<FormDefTypes>({
    initialValues: defaultFormValues({ freeText, geo, queryId: initialQueryId }),
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const handleValuesUpdate = (
    update: Partial<FormDefTypes>,
    analyticsEventCreator?: EventCreatorType,
  ) => {
    const isChanged = !checkIfPartialEqual(formik.values, update);

    if (!isChanged) return;
    const updatedValues = { ...formik.values, ...update, queryId: uuid() };
    const { freeText: updateFreeText } = update;
    if (updateFreeText) {
      const { type } = updateFreeText;
      if (type && type === TypeaheadTypes.provider) {
        if (analyticsEventCreator) {
          const events = analyticsEventCreator(null, getSessionId(), brokerId);
          for (const currEvent of events) {
            const { eventName, params } = currEvent;
            analytics.track(eventName, params);
          }
        }
        navToPage({
          page: 'ProviderDetails',
          params: {
            freeText: updateFreeText,
            geo: formik.values.geo,
            brokerId,
            user: { language },
            analyticsMetadata: analytics.metadata,
            memberToken,
          },
        });
        return;
      }
    }

    if (analyticsEventCreator) {
      const events = analyticsEventCreator(updatedValues.queryId, getSessionId(), brokerId);
      for (const currEvent of events) {
        const { eventName, params } = currEvent;
        analytics.track(eventName, params);
      }
    }

    formik.setValues(updatedValues);
    if (validateParams(updatedValues)) {
      setPageNumber(1);
    }
  };

  const handleTypeaheadEntered = (data: any, analyticsEventCreator?: EventCreatorType) => {
    if (analyticsEventCreator) {
      const events = analyticsEventCreator(formik.values.queryId, getSessionId(), brokerId);
      for (const currEvent of events) {
        const { eventName, params } = currEvent;
        analytics.track(eventName, params);
      }
    }
  };

  useEffect(() => {
    if (validateParams(formik.values)) {
      setSearchLoading(true);
      find({
        filters: {
          ...formik.values.filters,
          taxonomy: formik.values.freeText!.value,
        },
        geo: formik.values.geo,
        queryId: formik.values.queryId,
        limit: resultsLimit,
        skip: (pageNumber - 1) * resultsLimit,
        memberLanguage: language,
      })
        .then((result) => {
          if (result.providers.length === 0) {
            const { eventName, params } = SearchEvents.noResults({
              queryId: formik.values.queryId,
              memberSessionId: getSessionId(),
            });
            analytics.track(eventName, params);
          }
          setSearchResults(result);
        })
        .catch((e) => {
          logger.error(`Error performing find request!`, { e });
        })
        .then(() => {
          setSearchLoading(false);
        });
    }
  }, [formik.values, pageNumber, language, analytics]);

  return (
    <>
      <header>
        <h1 className="off-screen-text">
          {intl.formatMessage({ id: 'pages.mainSearch.searchResultsTitle' })}
        </h1>
        <TopForm
          geo={formik.values.geo}
          freeText={formik.values.freeText}
          isGoogleApiLoaded={isGoogleApiLoaded}
          onChange={handleValuesUpdate}
          language={language}
          onTypeaheadEntered={handleTypeaheadEntered}
        />
      </header>
      <FiltersMenu
        filters={formik.values.filters}
        enableReset={enableReset}
        onReset={() => {
          const updatedValues = {
            filters: { ...formik.values.filters, ...defaultFilterValues },
          };
          setEnableReset(!isEqual(updatedValues.filters, defaultFilterValues));

          handleValuesUpdate(updatedValues);
        }}
        userLanguage={language}
        onChange={(updates, analyticsEventCreator) => {
          const updateFilters = {
            ...formik.values.filters,
            ...updates,
          };
          setEnableReset(!isEqual(updateFilters, defaultFilterValues));

          handleValuesUpdate(
            {
              filters: updateFilters,
            },
            analyticsEventCreator,
          );
        }}
      />
      <main>
        {isSearchLoading ? (
          <Loader className="margin-top-30" theme={{ mainColor: theme.mainColor }} />
        ) : (
          <SearchResults
            searchResults={searchResults}
            queryId={formik.values.queryId}
            resultsLimit={resultsLimit}
            pageNumber={pageNumber}
            language={language}
            brokerId={brokerId}
            analyticsMetadata={analytics.metadata}
            onBookClick={({ npi, address, ranking }) => {
              const { eventName, params } = SearchActionEvents.bookClick({
                address,
                npi,
                ranking,
                queryId: formik.values.queryId,
                brokerId: getBrokerId(),
                memberSessionId: getSessionId(),
              });
              analytics.track(eventName, params);
            }}
            onChangePage={(newPageNumber) => {
              const { eventName, params } = SearchEvents.changePage({
                pageNumber: newPageNumber,
                prevPage: pageNumber,
                memberSessionId: getSessionId(),
                queryId: formik.values.queryId,
              });
              analytics.track(eventName, params);
              setPageNumber(newPageNumber);
            }}
            onBook={onBook}
          />
        )}
      </main>
    </>
  );
};

type MainSearchProps = IsGoogleLoaded &
  OnBook &
  withUserProp &
  withAnalyticsProp & { memberToken?: string };

export const MainSearchPageWithQs: FunctionComponent<MainSearchProps> = ({
  isGoogleApiLoaded,
  onBook,
  user,
  analytics,
  memberToken,
}) => {
  const query = useQueryString();
  const { freeText, brokerId } = query;
  const { language } = user;

  assertType(freeText, FreeTextTermValidator);
  assertType(language, LanguageValidator);
  assertType(brokerId, union([NonEmptyStringValidator, undefinedType]));

  const geoFromQuery = query?.geo as any;
  const geo = {
    geocode: {
      longitude: Number(geoFromQuery?.geocode?.longitude),
      latitude: Number(geoFromQuery?.geocode?.latitude),
    },
    address: geoFromQuery?.address,
  };
  assertType(geo, GeoCodeValidator);

  return (
    <MainSearchPage
      analytics={analytics}
      memberToken={memberToken}
      brokerId={brokerId}
      freeText={freeText}
      geo={geo}
      language={language}
      isGoogleApiLoaded={isGoogleApiLoaded}
      onBook={onBook}
    />
  );
};

export default MainSearchPage;
