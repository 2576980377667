interface Config {
  PUBLIC_API_URL: string;
  SEGMENT_API_KEY: string;
  GOOGLE_API_KEY: string;
  VIM_CLIENT_LOGGER: boolean;
  BOOKING_SDK_ENV: string;
  rollout__key: string;
}

const defaults: Partial<Config> = {
  VIM_CLIENT_LOGGER: false,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default { ...defaults, ...window.config } as Config;
