import React, { FunctionComponent } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import Text, { ColorNameEnum, SizeEnum } from '@getvim/components-atoms-text';
import es from './translations/es.json'
import './distanceChip.less';
import { defineMessages } from 'react-intl';
import withIntl, { WithIntlProps } from '@getvim/components-hocs-with-intl';

const messages = defineMessages({
  mile: {
    defaultMessage: 'mi',
    id: 'distanceChip.milesSuffix'
  },
});

const DistanceChipProps = {
  distance: PropTypes.number,
  showIcon: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.shape({
    mainColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
  }).isRequired,
};

type DistanceChipPropsType = InferProps<typeof DistanceChipProps>;

const DistanceChip: FunctionComponent<DistanceChipPropsType & WithIntlProps> = ({
  distance,
  showIcon,
  className,
  theme,
  intl,
}) => {
  if (distance == null) return null;
  return (
    <div className={classNames('distance', className)}>
      {showIcon ? <Text colorName={ColorNameEnum.themeSecondary} size={SizeEnum['16px']} className="distance-icon icon-map-pin-e" /> : null}
      <span>{distance} {intl.formatMessage(messages.mile)}</span>
    </div>
  );
};

DistanceChip.propTypes = DistanceChipProps;
DistanceChip.defaultProps = {
  distance: undefined,
  showIcon: true,
  className: '',
};

export default withIntl<DistanceChipPropsType>({ es })(DistanceChip);
