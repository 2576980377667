// eslint-disable-next-line
//@ts-ignore
import analyticsjsModule from './analyticsJSLoader';

// we omit track and identify because we decided to use only specific overload implementation of
// track and identify and not support all function overloads
export interface AnalyticsInstance
  extends Omit<SegmentAnalytics.AnalyticsJS, 'track' | 'identify' | 'page'> {
  isReady: boolean;
  track: (
    event: string,
    properties?: Record<string, any>,
    options?: SegmentAnalytics.SegmentOpts,
    callback?: () => void,
  ) => void;
  page: (
    category?: string,
    name?: string,
    properties?: Object,
    options?: SegmentAnalytics.SegmentOpts,
    callback?: () => void,
  ) => void;
  identify: (
    userId: string,
    traits?: Record<string, any>,
    options?: SegmentAnalytics.SegmentOpts,
    callback?: () => void,
  ) => void;
  metadata?: object;
}

const analyticsjs: ({
  writeKey,
  skipPageCall,
}: {
  writeKey: string;
  skipPageCall?: boolean;
}) => SegmentAnalytics.AnalyticsJS = analyticsjsModule as any;

export type withAnalyticsProp = { analytics: AnalyticsInstance };

export { analyticsjs };
