import React, { FunctionComponent } from 'react';
import ButtonGroup, {
  ButtonGroupPropTypes,
  ButtonGroupProps,
} from '@getvim/components-molecules-button-group';
import { languages, LangEnum, messages } from './languages';
import es from '../translations/es.json';
import withIntl, {
  getIntlMapper,
  IntlType,
  WithIntlProps,
} from '@getvim/components-hocs-with-intl';
import { defineMessages } from 'react-intl';

const uiMessages = defineMessages({
  searchLanguagePlaceholder: {
    defaultMessage: 'Search a language',
    id: 'languageSelector.searchLanguagePlaceholder',
  },
});

type OptionTypeTranslated = {
  value: LangEnum;
  text: string;
};

// eslint-disable-next-line no-unused-vars
const { items, ...PropTypes } = ButtonGroupPropTypes;

export type FilterOptions = {
  ids: string[];
};

type LanguagesSelectorProps = Omit<ButtonGroupProps, 'items' | 'value'> & { value: LangEnum } & {
  filterOptions?: FilterOptions;
  nativeLanguageLabels?: boolean;
};

const translateOptions: {
  en?: OptionTypeTranslated[];
  es?: OptionTypeTranslated[];
} = {};

function filterLanguageOptions(allOptions: OptionTypeTranslated[], filterOptions?: FilterOptions) {
  if (!filterOptions) {
    return allOptions;
  }

  return allOptions.filter(
    (option) => filterOptions.ids.includes(option.value) || option.value === 'ANY',
  );
}

const getOptions = (intl: IntlType, nativeLanguageLabels = false) => {
  const language = intl.locale as 'en' | 'es';

  if (!translateOptions[language]) {
    const commonLanguages: OptionTypeTranslated[] = [];
    const otherLanguages: OptionTypeTranslated[] = [];

    languages.forEach(({ id, name, nativeName }) => {
      const currLang = {
        text: nativeLanguageLabels ? nativeName : intl.formatMessage(name),
        value: id,
      };

      if (['en', 'es'].includes(id)) {
        commonLanguages.push(currLang);
      } else {
        otherLanguages.push(currLang);
      }
    });

    translateOptions[language] = [
      {
        value: 'ANY',
        text: intl.formatMessage(messages.any),
      },
      ...commonLanguages,
      ...otherLanguages.sort((a, b) => a.text.localeCompare(b.text, language)),
    ];
  }

  return translateOptions[language]!;
};

function LanguagesSelector({
  filterOptions,
  intl,
  nativeLanguageLabels = false,
  ...otherProps
}: LanguagesSelectorProps & WithIntlProps) {
  const options = filterLanguageOptions(getOptions(intl, nativeLanguageLabels), filterOptions);
  return (
    <ButtonGroup
      searchable
      items={options}
      searchPlaceholder={intl.formatMessage(uiMessages.searchLanguagePlaceholder)}
      {...otherProps}
    />
  );
}

LanguagesSelector.propTypes = PropTypes;

export { LangEnum };

const intlMapper = getIntlMapper({ es });

export function langEnumToName(id: LangEnum, language: 'en' | 'es', nativeLanguageLabels = false) {
  if (nativeLanguageLabels) {
    return (
      languages.find((lang) => lang.id === id)?.nativeName ||
      intlMapper[language].formatMessage(messages.any)
    );
  }
  const languageMessage = languages.find((lang) => lang.id === id)?.name || messages.any;
  return intlMapper[language].formatMessage(languageMessage);
}

export default withIntl<LanguagesSelectorProps>(
  { es },
  intlMapper,
)((LanguagesSelector as unknown) as FunctionComponent<LanguagesSelectorProps>);
