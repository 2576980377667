import Rox from 'rox-browser';

export interface ErrorContext {
  flagKey?: string;
}
type OnErrorFunction = (error: Error, context: ErrorContext) => void;

export interface FeatureFlagsClientProps {
  rolloutKey?: string;
  isCachingEnabled?: boolean;
  namespace?: string;
  onError?: OnErrorFunction;
}

export interface FeatureFlagContext {
  apiKey?: string;
  [index: string]: any;
}

/**
 * client for getting feature flags for rollout,
 * obtaining rollout key by one of two ways:
 * 1. dependency injection
 * 2. overriding obtainKey function to get the key dynamically
 */
export class FeatureFlagsClient<FlagKey extends string = string> {
  protected key?: string;
  private onError?: OnErrorFunction;
  public isCachingEnabled: boolean;
  public namespace: string;

  private cache: Record<string, string | boolean> = {};
  private initializationPromise: Promise<void> | undefined;

  constructor({ rolloutKey, isCachingEnabled, namespace = '', onError }: FeatureFlagsClientProps) {
    this.key = rolloutKey;
    this.isCachingEnabled = !!isCachingEnabled;
    this.namespace = namespace;
    this.onError = onError;
  }

  public async getFlag<TValue extends boolean | string>(
    flag: FlagKey,
    defaultValue: TValue,
    rawName: boolean = false,
    context: FeatureFlagContext | undefined = undefined,
  ): Promise<TValue> {
    try {
      const namespacedFlag = rawName ? flag : `${this.namespace}.${flag}`;

      if (this.isCachingEnabled && this.cache[namespacedFlag]) {
        return (this.cache[namespacedFlag] as unknown) as TValue;
      }
      await this.init();
      const value =
        typeof defaultValue === 'string'
          ? Rox.dynamicApi.value(namespacedFlag, defaultValue as string, context)
          : Rox.dynamicApi.isEnabled(namespacedFlag, (defaultValue as unknown) as boolean, context);
      return (value as unknown) as TValue;
    } catch (error) {
      if (!this.onError) throw error;
      this.onError(error, { flagKey: flag });
      return defaultValue;
    }
  }

  private async init() {
    if (!this.initializationPromise) {
      this.initializationPromise = new Promise(async (resolve, reject) => {
        try {
          if (!this.key) {
            this.key = await this.obtainKey();
          }
          await Rox.setup(this.key);
          resolve();
          return;
        } catch (err) {
          reject(err);
        }
      });
    }

    return this.initializationPromise;
  }

  protected async obtainKey(): Promise<string> {
    return this.key as string;
  }
}
