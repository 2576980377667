import React, { FunctionComponent, useEffect, useState } from 'react';
import { undefined as undefinedType, union } from 'io-ts';
import { convertDistance, getDistance } from 'geolib';
import { useIntl, FormattedMessage } from '@getvim/translate';
import { useFormik } from 'formik';
import { getGeoAddressAndZip } from '@getvim/components-molecules-location-select';
import useQueryString from '@getvim/components-hooks-use-query-string';
import Loader from '@getvim/components-atoms-loader';
import { useTheme } from '@getvim/components-hooks-use-theme';
import Text, { ColorNameEnum, SizeEnum, WeightEnum } from '@getvim/components-atoms-text';
import SvgNoResults from '@getvim/components-atoms-svgs/build/noResults';
import Container, { ContainerWidth } from '@getvim/components-atoms-container';
import Formatter from '@getvim/components-utils-formatter';
import {
  EventCreatorType,
  SearchActionEvents,
  withAnalyticsProp,
} from '@getvim/components-utils-analytics';
import { getByNpi, getCareTeam, searchActionAnalytics } from '../../api';
import { assertType, NonEmptyString, NonEmptyStringValidator } from '../../models/utils';
import { ProviderType } from '../../models/Provider';
import ResultCard from '../../components/resultCard/ResultCard';
import logger from '../../utils/logger';
import { Language, LanguageValidator } from '../../models/Language';
import { OnBook } from '../../components/bookButtonBySdk/BookButtonBySdk';
import { GeoCode, GeoCodeValidator } from '../../models/Geocode';
import usePageNav from '../../hooks/usePageNav';
import TopForm, { IsGoogleLoaded } from '../../components/topForm/TopForm';
import { defaultFormValues, FormDefTypes } from './formDef';
import { FreeTextTerm, FreeTextTermValidator, TypeaheadTypes } from '../../models/FreeText';
import { withUserProp } from '../../models/User';
import { getSessionId } from '../../api/tokensStorage';
import { getBrokerId } from '../../utils/brokerIdStorage';
import { CareTeam } from '../../components/CareTeam';

import './ProviderDetailsPage.less';

const MAX_DISTANCE_MILE = 500;
const SEARCH_BY_NAME_QUERY_ID = 'SEARCH_BY_NAME';
const PROVIDER_RANKING = 1;

function validateParams(params: FormDefTypes) {
  const { freeText, geo } = params;
  // Params here sometimes come from parent iframe or from query params, so we check them strictly
  return !!(
    freeText &&
    geo &&
    (geo.address || geo.zip || (geo.geocode && geo.geocode.latitude && geo.geocode.longitude))
  );
}

export type ProviderDetailsProps = IsGoogleLoaded &
  withAnalyticsProp &
  OnBook & {
    freeText: FreeTextTerm;
    geo: Partial<GeoCode>;
    language: Language;
    brokerId?: NonEmptyString;
    memberToken?: string;
  };

export const ProviderDetails: FunctionComponent<ProviderDetailsProps> = ({
  freeText,
  geo,
  language,
  brokerId,
  memberToken,
  isGoogleApiLoaded,
  onBook,
  analytics,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const navToPage = usePageNav();
  const [isProviderLoading, setIsProviderLoading] = useState(false);
  const [isCareTeamLoading, setIsCareTeamLoading] = useState(false);
  const [provider, setProvider] = useState<ProviderType>();
  const [careTeam, setCareTeam] = useState<ProviderType[]>();

  const formik = useFormik<FormDefTypes>({
    initialValues: defaultFormValues({ freeText, geo }),
    enableReinitialize: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (validateParams(formik.values)) {
      const [npi] = formik.values.freeText!.value;
      assertType(npi, NonEmptyStringValidator);

      setIsProviderLoading(true);
      getByNpi({ npi, memberLanguage: language })
        .then(async (providerByNpi) => {
          if (providerByNpi.locations.length === 0) {
            throw new Error('no location found');
          }
          let from: Partial<GeoCode> = formik.values.geo;
          if (!from.geocode) {
            const fullGeo = await getGeoAddressAndZip(formik.values.geo);
            assertType(fullGeo, GeoCodeValidator);
            from = fullGeo;
          }
          if (!from.geocode) {
            throw new Error('could not find geocode');
          }
          if (providerByNpi.locations.length > 1) {
            providerByNpi.locations.sort(
              (loc1, loc2) =>
                getDistance(from.geocode!, loc1.geo) - getDistance(from.geocode!, loc2.geo),
            );
          }
          const [firstLocation] = providerByNpi.locations;
          const distance = convertDistance(getDistance(from.geocode, firstLocation.geo), 'mi');
          if (distance > MAX_DISTANCE_MILE) {
            throw new Error(
              `provider is too far away, "${from.address}" is ${distance} mi from "${firstLocation.address}"`,
            );
          }

          setProvider(providerByNpi);
        })
        .catch((e) => {
          logger.error(`Failed to fetch provider ${npi}`, { e });
        })
        .then(() => setIsProviderLoading(false));
    }
  }, [formik.values, language]);

  useEffect(() => {
    if (provider) {
      const { npi } = provider;
      const { address } = provider.locations[0];
      assertType(npi, NonEmptyStringValidator);
      assertType(address, NonEmptyStringValidator);

      setIsCareTeamLoading(true);
      getCareTeam({ npi, address, memberLanguage: language, errorCallback: async () => {} })
        .then(setCareTeam)
        .catch((e) => {
          logger.error(`Failed to fetch careTeam for ${npi}`, { e });
        })
        .then(() => setIsCareTeamLoading(false));
    }
  }, [provider, language]);

  const handleValuesUpdate = (
    update: { geo?: Partial<GeoCode>; freeText?: FreeTextTerm },
    analyticsEventCreator: EventCreatorType,
  ) => {
    const updatedValues = { ...formik.values, ...update };
    const { freeText: updateFreeText } = update;

    const events = analyticsEventCreator(null, getSessionId(), getBrokerId());
    for (const currEvent of events) {
      const { eventName, params } = currEvent;
      analytics.track(eventName, params);
    }

    if (updateFreeText) {
      const { type } = updateFreeText;
      if (type && type === TypeaheadTypes.taxonomy) {
        navToPage({
          page: 'MainSearch',
          params: {
            freeText: updateFreeText,
            geo: formik.values.geo,
            brokerId,
            user: { language },
            analyticsMetadata: analytics.metadata,
            memberToken,
          },
        });
        return;
      }
    }

    formik.setValues(updatedValues);
  };

  return (
    <>
      <header>
        <h1 className="off-screen-text">
          {!provider
            ? intl.formatMessage({ id: 'general.detailsPage' })
            : intl.formatMessage(
                { id: 'pages.providerDetailsPage.detailsPageFor' },
                {
                  provider: Formatter.formatProviderTitle({
                    ...provider,
                    suffix: null,
                  }),
                },
              )}
        </h1>
        <TopForm
          provider={provider}
          geo={formik.values.geo}
          freeText={formik.values.freeText}
          isGoogleApiLoaded={isGoogleApiLoaded}
          onChange={handleValuesUpdate}
          language={language}
        />
      </header>
      <hr className="divider-banner" style={{ borderColor: theme.mainColor }} />
      <main className="provider-care-team-page padding-box-30">
        {isProviderLoading ? (
          <Loader theme={{ mainColor: theme.mainColor }} />
        ) : (
          <>
            {!provider ? (
              <div className="no-results text-center">
                <Container width={ContainerWidth.large}>
                  <Text
                    weight={WeightEnum.light}
                    className="title-36 margin-30"
                    colorName={ColorNameEnum.dark}
                    theme={theme}
                  >
                    <FormattedMessage id="pages.providerDetailsPage.noProviderFoundTitle" />
                  </Text>
                  <Text size={SizeEnum['22px']} className="margin-top-30" theme={theme}>
                    <FormattedMessage id="pages.providerDetailsPage.noProviderDetailsFound" />
                  </Text>
                  <div className="margin-top-30">
                    <SvgNoResults theme={theme} />
                  </div>
                </Container>
              </div>
            ) : (
              <>
                <ResultCard
                  provider={provider}
                  language={language}
                  queryId={SEARCH_BY_NAME_QUERY_ID}
                  brokerId={brokerId}
                  analyticsMetadata={analytics.metadata}
                  onBook={({ appointmentId, bookingType, ...payload }) => {
                    const [location] = provider.locations;
                    searchActionAnalytics({
                      actionType: bookingType!,
                      npi: provider.npi,
                      locationId: location.id,
                      queryId: SEARCH_BY_NAME_QUERY_ID,
                      ranking: PROVIDER_RANKING,
                      entityId: appointmentId,
                    }).then(() => {
                      if (onBook) {
                        onBook(payload);
                      }
                    });
                  }}
                  onBookClick={({ npi, address }) => {
                    const { eventName, params } = SearchActionEvents.bookClick({
                      address,
                      npi,
                      brokerId: getBrokerId(),
                      memberSessionId: getSessionId(),
                    });
                    analytics.track(eventName, params);
                  }}
                />
                {careTeam &&
                  (isCareTeamLoading ? (
                    <Loader size="small" theme={{ mainColor: theme.mainColor }} />
                  ) : (
                    <CareTeam
                      provider={provider}
                      careTeam={careTeam}
                      language={language}
                      brokerId={brokerId}
                      onBook={onBook}
                      analytics={analytics}
                    />
                  ))}
              </>
            )}
          </>
        )}
      </main>
    </>
  );
};

export const ProviderDetailsFromQS: FunctionComponent<
  IsGoogleLoaded & OnBook & withUserProp & withAnalyticsProp & { memberToken?: string }
> = ({ user, memberToken, isGoogleApiLoaded, onBook, analytics }) => {
  const query = useQueryString();
  // const query = {
  //   brokerId: '1234',
  //   freeText: '123',
  //   geo: { geocode: { latitude: '', longitude: '' } },
  // };
  const { freeText, brokerId } = query;
  const { language } = user;
  assertType(freeText, FreeTextTermValidator);
  assertType(language, LanguageValidator);
  assertType(brokerId, union([NonEmptyStringValidator, undefinedType]));
  const geoFromQuery = query?.geo as any;
  const geo = {
    geocode: {
      longitude: Number(geoFromQuery?.geocode?.longitude),
      latitude: Number(geoFromQuery?.geocode?.latitude),
    },
    address: geoFromQuery?.address,
  };
  assertType(geo, GeoCodeValidator);
  return (
    <ProviderDetails
      freeText={freeText}
      geo={geo}
      language={language}
      brokerId={brokerId}
      memberToken={memberToken}
      isGoogleApiLoaded={isGoogleApiLoaded}
      onBook={onBook}
      analytics={analytics}
    />
  );
};
