import loadDynamicScript from '@getvim/utils-load-dynamic-script';

declare global {
  interface Window {
    google: any;
  }
}

function snippetChecks(apiKey: string, systemName: string) {
  if (!apiKey) {
    return Promise.reject(new Error(`Can not init ${systemName} without an api key!`));
  }
  if (typeof document === 'undefined') {
    return Promise.reject(
      new Error(`document was not found, can only init ${systemName} on the client side!`),
    );
  }

  return Promise.resolve();
}

const loadGooglePlacesAutoCompleteApi = (apiKey: string, language: 'en' | 'es') => {
  if (!window?.google?.maps) {
    return snippetChecks(apiKey, 'Google places auto complete api')
      .then(() =>
        loadDynamicScript(
          'googleMaps',
          `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places${
            language !== 'en' ? `&language=${language}` : ''
          }`,
        ),
      )
      .then(() => {
        const {
          google: { maps },
        } = window;
        if (typeof maps === 'undefined') {
          throw new Error('Google api for places auto complete failed to initialize');
        }
        return true;
      });
  }
  return Promise.resolve(true);
};

export default loadGooglePlacesAutoCompleteApi;
