const suffixesThatShouldntBeUppercase: { [key: string]: string } = {
  MED: 'MeD',
  PHARMD: 'PharmD',
  EDD: 'EdD',
  AUD: 'AuD',
  PSYD: 'PsyD',
  PHD: 'PhD',
};

function parseSuffix(rawSuffix: string) {
  const rawSuffixInUppercase = rawSuffix.toUpperCase();
  return suffixesThatShouldntBeUppercase[rawSuffixInUppercase] ?? rawSuffixInUppercase;
}

function uniqueValuesFilter<T>(currentValue: T, currentIndex: number, sourceArray: T[]) {
  return sourceArray.indexOf(currentValue) === currentIndex;
}

class Formatter {
  /**
   * Most of the time, physician titles are in uppercase (like MD). Sometimes, they're not (like
   * PhD). This function takes the title/titles from the full name and parses them in the correct
   * capitalization. Also, this function takes into account that some providers have multiple titles
   * separated by comma.
   */
  static formatProviderTitle({
    title,
    firstName,
    middleName,
    lastName,
    suffix,
  }: {
    title?: string | null;
    firstName: string;
    middleName?: string | null;
    lastName: string;
    suffix?: string | null;
  }) {
    // get suffixes in array instead of comma limited string
    const suffixes = suffix?.length
      ? suffix
          .split(',')
          .map(s => s.trim())
          .filter(s => s.length)
      : [];
    const parsedSuffix = suffixes.map(parseSuffix).join(', ');
    const fullProviderTitleWithoutSuffix = [title, firstName, middleName, lastName]
      .filter(s => s?.length)
      .join(' ');
    return suffix
      ? `${fullProviderTitleWithoutSuffix}, ${parsedSuffix}`
      : fullProviderTitleWithoutSuffix;
  }

  static formatTaxonomies(
    taxonomies: {
      subSpecialtyDescription?: string | null;
    }[],
  ) {
    return taxonomies
      .map(tax => tax.subSpecialtyDescription)
      .filter(uniqueValuesFilter)
      .join(', ')
      .trim();
  }

  static formatPhoneToLocal(phoneNumber: { number: string; countryDialingCode: string }) {
    const cleaned = `${phoneNumber.number}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const [, areaCode, prefix, lineNumber] = match;
      return `(${areaCode}) ${prefix}-${lineNumber}`;
    }
    return phoneNumber.number;
  }

  static stringToPhoneNumber(
    s: string,
  ): {
    number: string;
    countryDialingCode: string;
  } {
    const digitsOnly = s.replace(/[^0-9]/g, '');
    return {
      countryDialingCode: '1',
      number: digitsOnly,
    };
  }

  static formatQsParamToArray(param?: any): any[] {
    if (!param) return param;
    return Array.isArray(param)
      ? param.map(tax => String(tax).trim())
      : String(param)
          .replace(/\s+/g, '')
          .split(',');
  }

  static addressFormat(address: any) {
    if (!address) return '';
    const addressArr: any[] = [];

    const pusher = (arr: any[], text: string, delimiter: string) => {
      if (arr.length > 0) {
        arr.push(`${delimiter}${text}`);
      } else {
        arr.push(`${text}`);
      }
    };

    if (address.addressLine1) {
      addressArr.push(`${address.addressLine1}`);
    }

    ['addressLine2', 'city', 'state'].forEach(property => {
      if (address[property]) pusher(addressArr, address[property], ', ');
    });

    if (address.zip) {
      pusher(addressArr, address.zip, ' ');
    }

    return addressArr.join('');
  }

}

export default Formatter;
