import qs from 'qs';
import { useLocation } from 'react-router-dom';

const defaultOption: qs.IParseOptions = {
  ignoreQueryPrefix: true,
  allowDots: true,
};

export default function useQueryString(options: qs.IParseOptions = defaultOption) {
  const location = useLocation();
  return qs.parse(location.search, options);
}
