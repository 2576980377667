import React from 'react';
import { defineMessages } from 'react-intl';
import ButtonGroup, {
  ButtonGroupPropTypes,
  ButtonGroupProps,
} from '@getvim/components-molecules-button-group';
import withIntl, {
  WithIntlProps,
  IntlType,
  getIntlMapper,
} from '@getvim/components-hocs-with-intl';
import es from '../translations/es.json';

export type FamilyPhysicianEnum = 'GENERAL' | 'INTERNIST' | 'ANY';

const FamilyPhysicianMessages = defineMessages({
  generalPractitioner: {
    defaultMessage: 'General Practitioner',
    id: 'familyPhysician.generalPractitioner',
  },
  internist: {
    defaultMessage: 'Internist',
    id: 'familyPhysician.internist',
  },
  any: {
    defaultMessage: 'Any',
    id: 'familyPhysician.any',
  },
});

const familyPhysicianToMessage = {
  GENERAL: FamilyPhysicianMessages.generalPractitioner,
  INTERNIST: FamilyPhysicianMessages.internist,
  ANY: FamilyPhysicianMessages.any,
};

function getFamilyPhysicianOptions(intl: IntlType) {
  return [
    {
      value: 'ANY',
      text: intl.formatMessage(familyPhysicianToMessage.ANY),
    },
    {
      value: 'GENERAL',
      text: intl.formatMessage(familyPhysicianToMessage.GENERAL),
    },
    {
      value: 'INTERNIST',
      text: intl.formatMessage(familyPhysicianToMessage.INTERNIST),
    },
  ];
}

type FamilyPhysicianSelectorProps = Omit<ButtonGroupProps, 'items' | 'value'> & { value: string };

// eslint-disable-next-line no-unused-vars
const { items, ...PropTypes } = ButtonGroupPropTypes;

function FamilyPhysicianSelector({ intl, ...props }: FamilyPhysicianSelectorProps & WithIntlProps) {
  const familyPhysicianOptions = getFamilyPhysicianOptions(intl);
  return <ButtonGroup items={familyPhysicianOptions} {...props} />;
}

FamilyPhysicianSelector.propTypes = PropTypes;

const intlMapper = getIntlMapper({ es });

export function getTranslatedFamilyPhysician(
  familyPhysician: 'GENERAL' | 'INTERNIST' | 'ANY',
  language: 'en' | 'es',
) {
  const intl = intlMapper[language];
  const familyPhysicianMessage = familyPhysicianToMessage[familyPhysician];
  return intl.formatMessage(familyPhysicianMessage);
}

export default withIntl<FamilyPhysicianSelectorProps>({ es }, intlMapper)(FamilyPhysicianSelector);
