import { array } from 'io-ts';
import redirectToErrorPage from '../utils/redirectToErrorPage';
import config from '../config';
import {
  getAccessToken,
  getAccessTokenExpiry,
  getApiKey,
  getApiKeyUnsafe,
  getSessionId,
  saveAccessToken,
  saveAccessTokenExpiry,
} from './tokensStorage';
import {
  FindRequest,
  FindResponse,
  FindResponseV,
  FreeTextResponse,
  FreeTextResponseV,
  LanguagesResponse,
  LanguagesResponseV,
  TaxonomiesResponse,
  TaxonomiesResponseV,
  ThemeResponse,
} from './types';
import logger from '../utils/logger';
import { assertType, NonEmptyString } from '../models/utils';
import { ProviderType, ProviderValidator } from '../models/Provider';
import { Language } from '../models/Language';
import { GeoCoordinates } from '../models/Geocode';
import { getBrokerId } from '../utils/brokerIdStorage';
import { sendLogToBackend } from './sendLogsToBackend';

const baseUrl = config.PUBLIC_API_URL;
const apiKeyHeader = 'x-api-key';
const authorizationHeader = 'authorization';
const contentTypeHeader = 'Content-Type';

const badRequestCode = 400;
const unauthorizedCode = 401;
const forbiddenCode = 403;
const notFoundCode = 404;
const internalErrorCode = 500;
const badGatewayCode = 502;

const errorCodes = [
  forbiddenCode,
  unauthorizedCode,
  notFoundCode,
  internalErrorCode,
  badGatewayCode,
  badRequestCode,
];

function baseHeaders() {
  return {
    [apiKeyHeader]: getApiKey(),
    [authorizationHeader]: `Bearer ${getAccessToken()}`,
    [contentTypeHeader]: 'application/json',
  };
}

let getJwtInProgress: boolean;
let getJwtPromise: Promise<void> | undefined;

function redirectOnError({ url, response }: { url: string; response: Response }) {
  if (!errorCodes.includes(response.status)) return Promise.resolve(response);
  // We use unsafe because want to redirect even if apiKey isn't valid
  const apiKey = getApiKeyUnsafe();

  sendLogToBackend({ location: `api/index.ts:redirectOnError`, apiKey, url, response });

  redirectToErrorPage(apiKey);
  // After the redirect, we don't want to resolve or reject the promise so we just return a pending promise.
  return new Promise(() => {}) as Promise<Response>;
}

export async function getJwt() {
  if (!getJwtInProgress) {
    getJwtInProgress = true;
    const url = `${baseUrl}/auth/getJwt`;
    getJwtPromise = Promise.resolve()
      .then(() => {
        const headers = {
          [apiKeyHeader]: getApiKey(),
        };
        return fetch(url, { headers });
      })
      .then((response) => {
        if (!response.ok) {
          logger.error('JWT refresh failed', {
            url,
            status: response.status,
            body: response.body,
          });
          return redirectOnError({ url, response });
        }
        return response.json();
      })
      .then((data) => {
        const { access_token: accessToken, expiry } = data.data;
        saveAccessToken(accessToken);
        saveAccessTokenExpiry(expiry);
        getJwtInProgress = false;
        logger.info('Refreshed JWT access token');
      })
      .catch((e) => {
        getJwtInProgress = false;
        throw e;
      });
  }
  return getJwtPromise;
}

const twoMinutesMillis = 1000 * 60 * 2;
function needToRefreshAccessToken(): boolean {
  const expiry = getAccessTokenExpiry();
  if (expiry) {
    const expiryDate = new Date(expiry);
    const now = new Date();
    return expiryDate.getTime() - now.getTime() < twoMinutesMillis;
  }
  return true;
}

async function publicApiFetch(
  url: RequestInfo,
  fetchConfig?: RequestInit,
  options?: ApiFunctionOptions,
): Promise<any> {
  const { errorCallback } = options || {};
  const fullUrl = `${baseUrl}/${url}`;

  const accessToken = getAccessToken();
  if (!accessToken || needToRefreshAccessToken()) await getJwt();

  let newFetchConfig = {
    ...fetchConfig,
    headers: {
      ...baseHeaders(),
      ...fetchConfig?.headers,
    },
  };
  let response = await fetch(fullUrl, newFetchConfig);
  if (response.status === unauthorizedCode) {
    logger.info(`Got UNAUTHORIZED, refreshing access token`);
    await getJwt();
    newFetchConfig = {
      ...fetchConfig,
      headers: {
        ...baseHeaders(),
        ...fetchConfig?.headers,
      },
    };
    response = await fetch(fullUrl, newFetchConfig);
  }

  if (!response.ok) {
    logger.error('API returned Error', {
      url,
      params: newFetchConfig,
      status: response.status,
      body: response.body,
    });
    const handleError = errorCallback || redirectOnError;
    return handleError({ url: fullUrl, params: newFetchConfig, response });
  }

  return response.json();
}

type ApiFunctionOptions = {
  errorCallback: (args: { url: string; params?: any; response: Response }) => Promise<any>;
};

type ApiFunctionType<T, R> = (params: T, options?: ApiFunctionOptions) => Promise<R>;

type ApiFunctionNoPayload<R> = (options?: ApiFunctionOptions) => Promise<R>;

export const find: ApiFunctionType<FindRequest, FindResponse['data']> = async ({
  queryId,
  filters: {
    gender,
    spokenLanguage,
    taxonomy,
    acceptNewPatients,
    offerTelemedicine,
    sanitasPlus,
    ...filters
  },
  geo,
  limit,
  skip,
  memberLanguage,
}) => {
  const query = {
    queryId,
    memberSessionId: getSessionId(),
    brokerId: getBrokerId(),
    filters: {
      ...filters,
      taxonomy: taxonomy || undefined,
      gender: gender === 'ANY' ? undefined : gender,
      spokenLanguage: spokenLanguage === 'ANY' ? undefined : spokenLanguage,
      offerTelemedicine: offerTelemedicine || undefined,
      acceptNewPatients: acceptNewPatients || undefined,
      sanitasPlus: sanitasPlus || undefined,
    },
    geo,
    limit,
    skip,
    memberLanguage,
  };
  logger.info('Performing find request', { query });
  const findResponse = await publicApiFetch('provider', {
    method: 'POST',
    body: JSON.stringify(query),
  });

  assertType(findResponse, FindResponseV);
  return findResponse.data;
};

export const getByNpi: ApiFunctionType<
  {
    npi: NonEmptyString;
    memberLanguage?: Language;
  },
  ProviderType
> = async ({ npi, memberLanguage }) => {
  logger.info('getByNpi fetch request', { npi });
  let query = `${npi}`;
  if (memberLanguage) {
    query += `?memberLanguage=${memberLanguage}`;
  }
  const provider = await publicApiFetch(`provider/${query}`);
  assertType(provider, ProviderValidator);
  return provider;
};

export const freeText: ApiFunctionType<
  { text: string; geo?: GeoCoordinates; memberLanguage?: Language },
  FreeTextResponse['data']
> = async ({ text, geo, memberLanguage }) => {
  logger.info('Free text fetch request', text);
  const response = await publicApiFetch('provider/freeText', {
    method: 'POST',
    body: JSON.stringify({
      freeText: text,
      memberLanguage,
      ...(geo ? { geo: { geocode: geo } } : {}),
    }),
  });

  assertType(response, FreeTextResponseV);
  return response.data;
};

export const getCareTeam: ApiFunctionType<
  {
    npi: NonEmptyString;
    address: NonEmptyString;
    memberLanguage?: Language;
    errorCallback: () => Promise<any>;
  },
  ProviderType[]
> = async ({ npi, address, memberLanguage, errorCallback }) => {
  logger.info('Fetching care teams', { npi, address });
  const providers = await publicApiFetch(
    'provider/careTeam',
    {
      method: 'POST',
      body: JSON.stringify({ filters: { npi, address }, memberLanguage }),
    },
    {
      errorCallback,
    },
  );
  assertType(providers, array(ProviderValidator));
  return providers;
};

export const getAllTaxonomies: ApiFunctionType<
  { memberLanguage?: Language },
  TaxonomiesResponse['data']
> = async ({ memberLanguage }) => {
  logger.info('Getting all taxonomies');
  const taxonomies = await publicApiFetch(
    `provider/taxonomies?type=specialty${memberLanguage && `&memberLanguage=${memberLanguage}`}`,
    {},
    {
      errorCallback: () => {
        return Promise.resolve({ data: [] });
      },
    },
  );

  assertType(taxonomies, TaxonomiesResponseV);
  return taxonomies.data;
};

export const getAllLanguages: ApiFunctionNoPayload<LanguagesResponse['data']> = async () => {
  logger.info('Getting all languages');
  const languages = await publicApiFetch('provider/languages');
  assertType(languages, LanguagesResponseV);
  return languages.data;
};

export function searchActionAnalytics({
  actionType,
  npi,
  locationId,
  queryId,
  ranking,
  entityId,
}: {
  actionType: string;
  npi: string;
  locationId: number;
  ranking: number;
  queryId: string;
  entityId?: string;
}): Promise<void> {
  // Usually don't wait for this promise, we shouldn't wait for analytics to occur (similar to segment)
  // However, this promise was written for cases when the iframe should close after this request -
  // Wait max of 1 second (or until request fulfilled) before resolving to allow request to be made
  return new Promise((resolve) => {
    publicApiFetch('search/actionAnalytics', {
      method: 'POST',
      body: JSON.stringify({
        queryId,
        memberSessionId: getSessionId(),
        actionType,
        npi,
        locationId,
        ranking,
        entityId,
      }),
    })
      .catch((err) => logger.error('Action analytics failed', err))
      .then(resolve);

    setTimeout(resolve, 1000);
  });
}

export const getTheme: ApiFunctionType<
  {
    themeName: string;
    errorCallback: () => Promise<any>;
  },
  ThemeResponse
> = async ({ themeName, errorCallback }) => {
  logger.info('Fetching theme', { theme: themeName });

  const theme = await publicApiFetch(
    `theme/${themeName}`,
    {
      method: 'GET',
    },
    {
      errorCallback,
    },
  );

  return theme;
};
