import React, { FunctionComponent } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import RoundedImage from '@getvim/components-atoms-rounded-image';
import es from './translations/es.json';
import highValueLogo from './highValueLogo.png';
import withIntl, { WithIntlProps } from '@getvim/components-hocs-with-intl';
import { defineMessages } from 'react-intl';
import './ProviderLogo.less';

const messages = defineMessages({
  highValueAlternativeMessage: {
    defaultMessage: 'high value physician',
    id: 'providerLogo.highValueAlternativeMessages'
  },
});

const ProviderProps = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  profilePicture: PropTypes.string,
  hvns: PropTypes.arrayOf(
    PropTypes.shape({
      domain: PropTypes.string,
    }),
  ),
};

const ProviderLogoProps = {
  provider: PropTypes.shape(ProviderProps).isRequired,
  /**
   * The css class name based on doctor img css class.
   */
  containerClass: PropTypes.string,
  /**
   * An alternative custom css class instead of @containerClass.
   */
  className: PropTypes.string,
  hidePicture: PropTypes.bool,
  isHighValueBadgeEnable: PropTypes.bool,
};

type ProviderLogoPropsType = InferProps<typeof ProviderLogoProps>;

const ProviderLogo: FunctionComponent<ProviderLogoPropsType & WithIntlProps> = ({
  provider: { firstName, lastName, profilePicture, hvns },
  containerClass,
  className,
  hidePicture,
  isHighValueBadgeEnable,
  intl,
}) => {
  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;
  let providerImage = (
    <div className={classNames('doctor-img ', containerClass)}>
      <span className="content" aria-hidden="true">
        {initials}
      </span>
    </div>
  );
  if (profilePicture && !hidePicture) {
    providerImage = (
      <RoundedImage
        className={classNames(className || 'doctor-img', containerClass)}
        src={profilePicture}
      />
    );
  }

  return (
    <span className="pic-wrap">
      {providerImage}
      {isHighValueBadgeEnable && !!hvns?.length && (
        <img src={highValueLogo} className="high-value-logo" alt={intl.formatMessage(messages.highValueAlternativeMessage)} />
      )}
    </span>
  );
};

ProviderLogo.propTypes = ProviderLogoProps;
ProviderLogo.defaultProps = {
  className: undefined,
  containerClass: 'big',
  hidePicture: false,
  isHighValueBadgeEnable: false,
};

export default withIntl<ProviderLogoPropsType>({ es })(ProviderLogo);
