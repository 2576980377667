import React, { FunctionComponent, useEffect, useState } from 'react';
import DistanceSlider from '@getvim/components-molecules-distance-slider';
import Button from '@getvim/components-atoms-button';
import CheckboxInput from '@getvim/components-atoms-checkbox-input';
import Text, {
  AlignEnum,
  WeightEnum,
  SizeEnum,
  ColorNameEnum,
} from '@getvim/components-atoms-text';
import { BkmdModal, ModalHeader } from '@getvim/components-atoms-bkmd-modal';
import { useIntl, FormattedMessage } from '@getvim/translate';
import {
  GenderPopover,
  GenderEnum,
  LanguagePopover,
  LangEnum,
  FilterOptions,
} from '@getvim/components-molecules-filters';
import { useTheme } from '@getvim/components-hooks-use-theme';
import { SearchFiltersType, FiltersType } from '../typesDef';

import './FiltersModal.less';

interface FiltersModalType {
  isOpen: boolean;
  onClose: () => void;
  languageOptions?: FilterOptions;
}

const FiltersModal: FunctionComponent<FiltersModalType & SearchFiltersType> = ({
  userLanguage,
  languageOptions,
  filters,
  isOpen,
  onClose,
  onChange,
  onReset,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const [localChanges, setLocalChanges] = useState<Partial<FiltersType>>({});

  useEffect(() => {
    setLocalChanges({});
  }, [filters]);

  const localFilters = {
    ...filters,
    ...localChanges,
  };

  const themeForCheckbox = {
    ...theme,
    secondaryColor: theme.mainColor,
  };

  return (
    <BkmdModal
      isOpen={isOpen}
      handleClose={onClose}
      className="fullscreen-modal filters-modal"
      closeOnBackdrop={false}
    >
      <ModalHeader className="margin-13">
        <Button
          onClick={onClose}
          className="close-dialog-btn"
          aria-label="close"
          buttonType="link"
          color="darkGray"
          theme={theme}
        >
          <i className="icon-x text-18 i-va-fix-3" />
        </Button>
      </ModalHeader>
      <div className="dialog-body">
        <Text
          size={SizeEnum['12px']}
          colorName={ColorNameEnum.extraDark}
          theme={theme}
          weight={WeightEnum.bold}
          align={AlignEnum.left}
        >
          <FormattedMessage id="general.distance" />
        </Text>
        <DistanceSlider
          className="clean-slider"
          value={localFilters.distance}
          onChange={({ target: { value } }) => {
            setLocalChanges({
              ...localChanges,
              distance: Number(value),
            });
          }}
          theme={theme}
        />
        <Text
          theme={theme}
          weight={WeightEnum.bold}
          align={AlignEnum.left}
          size={SizeEnum['12px']}
          colorName={ColorNameEnum.extraDark}
          className="margin-top-30 margin-bottom-20"
        >
          <FormattedMessage id="general.gender" />
        </Text>

        <div className="filter-section">
          <GenderPopover
            btnType="outline"
            btnWidth="block"
            theme={theme}
            value={localFilters.gender}
            language={userLanguage}
            onChange={(selectedGender: GenderEnum) =>
              setLocalChanges({
                ...localChanges,
                gender: selectedGender,
              })
            }
          />
        </div>

        <div className="filter-section">
          <Text
            theme={theme}
            weight={WeightEnum.bold}
            align={AlignEnum.left}
            size={SizeEnum['12px']}
            colorName={ColorNameEnum.extraDark}
            className="margin-top-30 margin-bottom-20"
          >
            <FormattedMessage id="general.language" />
          </Text>
          <LanguagePopover
            btnType="outline"
            btnWidth="block"
            theme={theme}
            options={languageOptions}
            value={localFilters.spokenLanguage}
            language={userLanguage}
            onChange={(selectedSpokenLanguages: LangEnum) =>
              setLocalChanges({
                ...localChanges,
                spokenLanguage: selectedSpokenLanguages,
              })
            }
          />
        </div>

        <div>
          <CheckboxInput
            className="fancy-checkbox nowrap margin-top-30"
            id="offerTelemedicine"
            type="checkbox"
            checked={localFilters.offerTelemedicine}
            label={intl.formatMessage({ id: 'components.filters.offersVirtualVisits' })}
            themedOutline
            theme={themeForCheckbox}
            onChange={(value) =>
              setLocalChanges({
                ...localChanges,
                offerTelemedicine: value,
              })
            }
          />
        </div>

        <div>
          <CheckboxInput
            className="fancy-checkbox nowrap margin-top-30"
            id="sanitasPlus"
            type="checkbox"
            checked={localFilters.sanitasPlus}
            label={intl.formatMessage({ id: 'components.filters.medicareLabel' })}
            theme={themeForCheckbox}
            themedOutline
            onChange={(value) =>
              setLocalChanges({
                ...localChanges,
                sanitasPlus: value,
              })
            }
          />
        </div>
      </div>
      <div className="dialog-footer fixed-footer">
        <div className="row tiny-gutter">
          <div className="col-xs-6">
            <Button
              onClick={onReset}
              data-id="reset-filters"
              theme={theme}
              className="left-action-btn"
              buttonType="small"
              width="block"
              bgColor="themedOutline"
            >
              <FormattedMessage id="components.filters.clearFilters" />
            </Button>
          </div>
          <div className="col-xs-6">
            <Button
              onClick={() => {
                onChange(localChanges);
              }}
              data-id="apply-filters"
              theme={theme}
              buttonType="small"
              width="block"
            >
              <FormattedMessage id="general.apply" />
            </Button>
          </div>
        </div>
      </div>
    </BkmdModal>
  );
};

export default FiltersModal;
