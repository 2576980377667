import React, { useEffect, useMemo } from 'react';
import Select, { Props, OptionTypeBase } from 'react-select';
import Loader from '@getvim/components-atoms-loader';

export interface DefaultOptionType extends OptionTypeBase {
  label: string;
  value: string;
}

type ThemeType = {
  mainColor: string;
  secondaryColor: string;
};

type ValueCompareType = (value: any, optionValue: any) => boolean;

export interface SelectInputType<T extends OptionTypeBase = DefaultOptionType>
  extends Omit<Props<T>, 'theme'> {
  options?: T[];
  onChange: (selectedOptions: T[]) => void;
  selectLabel?: string;
  theme: ThemeType;
  valueCompare?: ValueCompareType | ValueCompareType[];
  name?: string;
  customComponents?: Partial<Props['components']>;
  inputRef?: any;
  firstFetch?: boolean;
  autoFocusValue?: boolean;
  iconClassName?: string;
  isClearable?: boolean;
}

const styles = (theme: ThemeType) => ({
  option: (styles: any, state: any) => ({
    ...styles,
    background: state.isFocused || state.isSelected ? theme.secondaryColor : 'white',
    ':active': {
      backgroundColor: theme.secondaryColor,
    },
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    borderBottom: state.menuIsOpen || state.isFocused ? `1px solid ${theme.mainColor}` : null,
    '&:hover': {
      borderBottom: state.menuIsOpen ? `1px solid ${theme.mainColor}` : null,
    },
  }),
});

function findSelectedOption<T extends OptionTypeBase = DefaultOptionType>(
  value: any,
  options: T[],
  valueCompare: ValueCompareType[],
): T[] | null {
  if (!value) return null;
  for (const currCompareFunc of valueCompare) {
    const found = options.filter((option) => currCompareFunc(value, option.value));

    if (found.length) return found;
  }
  return null;
}

function SelectInput<T extends OptionTypeBase = DefaultOptionType>({
  selectLabel,
  placeholder,
  options,
  value,
  valueCompare = [(value, optionValue) => optionValue === value],
  theme,
  customComponents = {},
  name,
  inputRef,
  onChange,
  firstFetch,
  autoFocusValue = false,
  iconClassName,
  isClearable = true,
  ...props
}: SelectInputType<T>) {
  const selectedOption = useMemo(
    () =>
      findSelectedOption<T>(
        value,
        options,
        Array.isArray(valueCompare) ? valueCompare : [valueCompare],
      ),
    [value, options],
  );
  useEffect(() => {
    if (firstFetch && selectedOption) {
      onChange(selectedOption);
    }
  }, [selectedOption, firstFetch]);

  return (
    <div className="select-input">
      <Select
        aria-label={selectLabel || placeholder}
        className={`Select ${iconClassName}`}
        classNamePrefix="Select"
        placeholder={placeholder}
        options={options}
        value={selectedOption?.[0]}
        isClearable={isClearable}
        styles={styles(theme)}
        ref={inputRef}
        autoFocus={autoFocusValue}
        components={{
          LoadingIndicator: () => (
            <Loader type="dots" theme={theme} size="small" color="grey" padding="none" />
          ),
          ...customComponents,
        }}
        onChange={(option) => onChange(option ? [option] : null)}
        {...props}
      />
      <label className="select-label">{selectLabel}</label>
    </div>
  );
}

// Export types to use for custom components
export {
  components as ReactSelectComponents,
  ControlProps,
  GroupProps,
  InputProps,
  MenuProps,
  MenuListComponentProps,
  MultiValueProps,
  OptionProps,
  PlaceholderProps,
  SingleValueProps,
  ValueContainerProps,
} from 'react-select';

export { SelectComponents as SelectComponentsType } from 'react-select/src/components';

export default SelectInput;
