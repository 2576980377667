import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import SvgPageNotFound from '@getvim/components-atoms-svgs/build/pageNotFound';
import Button from '@getvim/components-atoms-button';
import Text, {
  AlignEnum,
  ColorNameEnum,
  SizeEnum,
  WeightEnum,
} from '@getvim/components-atoms-text';
import './errorPage.less';

export interface ErrorPageProps {
  title: string;
  className?: string;
  description: string;
  buttonText?: string;
  errorText?: string;
  onSubmit?: () => void;
  displayError?: boolean;
  displayButton?: boolean;
  onClose?: () => void;
  theme: {
    mainColor: string;
    secondaryColor: string;
    linkColor: string;
    buttonColor: string;
  };
  errorIcon?: JSX.Element;
}

const ErrorPage: FunctionComponent<ErrorPageProps> = ({
  title,
  description,
  buttonText,
  errorText,
  displayError = false,
  displayButton = true,
  onSubmit,
  errorIcon = <SvgPageNotFound />,
  onClose,
  theme,
  className,
}) => (
  <div className={classNames('error-page-wrap', className)}>
    {onClose && (
      <Button onClick={onClose} buttonType="link" className="close-button" theme={theme}>
        <Text
          className="icon-x"
          colorName={ColorNameEnum.ultraDark}
          size={SizeEnum['18px']}
          theme={theme}
        />
      </Button>
    )}
    <Text
      colorName={ColorNameEnum.theme}
      size={SizeEnum['18px']}
      weight={WeightEnum.bold}
      align={AlignEnum.center}
      text={title}
      theme={theme}
    />
    <Text
      className="margin-top-10 margin-bottom-45"
      size={SizeEnum['14px']}
      colorName={ColorNameEnum.ultraDark}
      align={AlignEnum.center}
      text={description}
      theme={theme}
    />
    <div className="row">{errorIcon}</div>
    {displayButton ? (
      <div className="row">
        <Button
          className="margin-top-50"
          buttonType="small"
          bgColor="themedOutline"
          onClick={onSubmit}
          theme={theme}
        >
          {buttonText}
        </Button>
      </div>
    ) : null}
    <div className={classNames('row dev-error', { visible: displayError })}>{errorText}</div>
  </div>
);

export default ErrorPage;
