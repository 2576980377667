import { BaseSearchEvent, EventResult } from './analyticsTypes';

const ShareClick = 'share click';
const ShareSubmit = 'share submit click';
const ShareSubmitFailed = 'share submit failed';
const DetailsClick = 'show details click';
const ShowMaps = 'PROVIDER_MAP_LOADED';
const SdkBookButtonOnProfileClicked = 'SDK book button on profile page clicked';
export const SdkBookButtonClicked = 'SDK book button clicked';

export const SearchActionEvents = {
  bookClick: ({
    npi,
    address,
    ranking,
    queryId,
    memberSessionId,
    brokerId,
  }: BaseSearchEvent & { npi: string; address: string; ranking?: number }): EventResult => ({
    eventName: SdkBookButtonClicked,
    params: { npi, address, ranking, queryId, memberSessionId, brokerId },
  }),
  bookOnProfileClick: ({
    npi,
    address,
    ranking,
    queryId,
    memberSessionId,
    brokerId,
  }: BaseSearchEvent & { npi: string; address: string; ranking?: number }): EventResult => ({
    eventName: SdkBookButtonOnProfileClicked,
    params: { npi, address, ranking, queryId, memberSessionId, brokerId },
  }),
  shareClick({
    npi,
    address,
    ranking,
    queryId,
    memberSessionId,
    brokerId,
  }: BaseSearchEvent & { npi: string; address: string; ranking?: number }): EventResult {
    return {
      eventName: ShareClick,
      params: { npi, address, ranking, queryId, memberSessionId, brokerId },
    };
  },
  shareSubmitClick({
    npi,
    address,
    ranking,
    queryId,
    memberSessionId,
    brokerId,
  }: BaseSearchEvent & { npi: string; address: string; ranking?: number }): EventResult {
    return {
      eventName: ShareSubmit,
      params: { npi, address, ranking, queryId, memberSessionId, brokerId },
    };
  },
  shareFailed({
    error,
    npi,
    locationId,
    memberSessionId,
    queryId,
    brokerId,
  }: BaseSearchEvent & { error: any; npi: string; locationId: number }): EventResult {
    return {
      eventName: ShareSubmitFailed,
      params: {
        npi,
        locationId,
        memberSessionId,
        queryId,
        errorMessage: error?.responseMessage,
        errorStatus: error?.status,
        brokerId,
      },
    };
  },
  detailsClick({
    npi,
    address,
    ranking,
    queryId,
    memberSessionId,
    brokerId,
  }: BaseSearchEvent & { npi: string; address: string; ranking?: number }): EventResult {
    return {
      eventName: DetailsClick,
      params: { npi, address, ranking, queryId, memberSessionId, brokerId },
    };
  },
  mapsLoad({ mapType }: BaseSearchEvent & { mapType: string }): EventResult {
    return {
      eventName: ShowMaps,
      params: { mapType },
    };
  },
};
