import React, { FunctionComponent } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Button, { ButtonProps } from '@getvim/components-atoms-button';
import { defineMessages } from 'react-intl';
import WithIntl, { WithIntlProps, IntlType, WithLanguageProps } from '@getvim/components-hocs-with-intl';
import es from './translations/es.json';

const buttonText = defineMessages({
  schedule: {
    defaultMessage: 'Schedule',
    id: 'bookButton.scheduleText'
  },
  book: {
    defaultMessage: 'Book',
    id: 'bookButton.bookText'
  },
  request: {
    defaultMessage: 'Request appointment',
    id: 'bookButton.requestText'
  }
});

export enum BookingType {
  CONCIERGE = 'CONCIERGE',
  HYBRID = 'HYBRID',
  REQUEST = 'REQUEST',
  INSTANT = 'INSTANT',
  CALL = 'CALL',
  NONE = 'NONE',
}

const BookButtonProps = {
  bookingType: PropTypes.oneOf(Object.values(BookingType)).isRequired,
  btnClassName: PropTypes.string,
  scheduleButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    buttonColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
    linkColor: PropTypes.string.isRequired,
    mainColor: PropTypes.string.isRequired,
  }).isRequired,
};

const getButtonText = (bookingType: BookingType, scheduleButton: boolean, intl: IntlType) => {
  if (bookingType === BookingType.CONCIERGE)
    return scheduleButton ? intl.formatMessage(buttonText.schedule) : intl.formatMessage(buttonText.request);
  if (bookingType === BookingType.HYBRID || bookingType === BookingType.REQUEST)
    return intl.formatMessage(buttonText.request);
  return intl.formatMessage(buttonText.book);
};
export type BookButtonPropsType = InferProps<typeof BookButtonProps> & ButtonProps;

const BookButton: FunctionComponent<BookButtonPropsType & WithIntlProps> = ({
  btnClassName,
  scheduleButton,
  bookingType,
  isLoading,
  onClick,
  theme,
  intl,
  ...other
}) => {
  if (!isLoading && (bookingType == null || bookingType === BookingType.NONE)) return null;
  const buttonText = getButtonText(bookingType, scheduleButton || false, intl);
  return (
    <Button
      className={btnClassName || undefined}
      onClick={
        e => {
          e.stopPropagation();
          onClick(e);
        }
      }
      isLoading={isLoading}
      theme={theme}
      {...other}
    >
      <span>
        {scheduleButton && bookingType === BookingType.CONCIERGE && (
          <i className="icon-datepicker-s i-va-fix-2" />
        )}
        {bookingType === BookingType.INSTANT && <i className="icon-lightning i-va-fix-3" />}
        &nbsp;
        {buttonText}
      </span>
    </Button>
  );
};

BookButton.propTypes = BookButtonProps;
BookButton.defaultProps = {
  btnClassName: undefined,
  scheduleButton: false,
  isLoading: false,
};

const BookButtonWithIntl : FunctionComponent<BookButtonPropsType & WithLanguageProps> = WithIntl<BookButtonPropsType>({ es })(BookButton);
export default BookButtonWithIntl;
