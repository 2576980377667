import React, { FunctionComponent } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import './container.less';

export enum ContainerWidth {
  default = 'col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 no-padding',
  small = 'col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4 no-padding',
  large = 'col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1 no-padding',
}

const ContainerProps = {
  width: PropTypes.oneOf(Object.values(ContainerWidth)),
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool,
};

type ContainerPropsType = InferProps<typeof ContainerProps>;

const Container: FunctionComponent<ContainerPropsType> = ({
  width,
  children,
  withHeader,
  withFooter,
}) => (
  <div
    className={classNames(
      'container container-1040',
      { 'with-header': withHeader },
      { 'has-footer footer-not-fixed': withFooter },
    )}
  >
    <div className="row no-margin padding-h-30">
      <div className={classNames(width)}>{children}</div>
    </div>
  </div>
);

Container.propTypes = ContainerProps;
Container.defaultProps = {
  width: ContainerWidth.default,
  withHeader: false,
  withFooter: false,
};

export default Container;
