import React, { FunctionComponent } from 'react';
import BookingHorizon from '@getvim/components-atoms-booking-horizon-badge';
import { useTheme } from '@getvim/components-hooks-use-theme';
import ProviderLogo from '@getvim/components-atoms-provider-logo';
import DistanceChip from '@getvim/components-atoms-distance-chip';
import Availability, { AvailabilitySizeEnum } from '@getvim/components-organisms-availability';
import ResultCardUI from '@getvim/components-molecules-result-card';
import Formatter from '@getvim/components-utils-formatter';
import Button from '@getvim/components-atoms-button';
import { useIntl } from '@getvim/translate';
import { ProviderType } from '../../models/Provider';
import BookButtonBySdk, { OnBook } from '../bookButtonBySdk/BookButtonBySdk';
import { Language } from '../../models/Language';
import { NonEmptyString } from '../../models/utils';

type ResultCardProps = OnBook & {
  provider: ProviderType;
  onProviderDetailsClick?: () => void;
  onBookClick?: (params: { npi: string; address: string }) => void;
  language: Language;
  queryId: string;
  brokerId?: NonEmptyString;
  analyticsMetadata?: any;
};

const ResultCard: FunctionComponent<ResultCardProps> = ({
  provider,
  language,
  brokerId,
  onBook,
  onProviderDetailsClick,
  onBookClick,
  queryId,
  analyticsMetadata,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const [location] = provider.locations;
  const taxonomies = Formatter.formatTaxonomies(provider.taxonomies);
  const fullProviderName = Formatter.formatProviderTitle(provider);
  const languagesStringArray = provider.languages.map((lang) => lang.name);
  const distance = location.distance ? Math.round(location.distance * 10) / 10 : undefined;
  const profileUrl = location.implementationSpecificFields?.webpage;
  const bookingHorizonDays = location.bookingHorizonDays ? location.bookingHorizonDays : undefined;

  // This function will return a search query in this format: ?utm_XXX=YYYY&utm_ZZZ=YYYY
  const extractUTMQueryParams = () => {
    try {
      const utmParams = Object.keys(analyticsMetadata?.utm);
      const query = utmParams.reduce(
        (partQuery, param) => `${partQuery}${param}=${analyticsMetadata.utm[param]}&`,
        '',
      );

      return query.length ? `?${query.slice(0, -1)}` : '';
    } catch (error) {
      return '';
    }
  };

  // TODO The distanceChip colors don't not fit the design. In the design - secondaryColor
  const themeForDistance = {
    ...theme,
    mainColor: theme.secondaryColor,
  };

  return (
    <ResultCardUI
      id={provider.npi}
      key={provider.npi}
      horizontalActionButtons
      resultImg={<ProviderLogo provider={provider} isHighValueBadgeEnable language={language} />}
      resultMainText={fullProviderName}
      resultMainIcon={
        location.implementationSpecificFields?.telehealth ? (
          <i className="icon-video-cam telehealth-icon" style={{ color: theme.accentColor }} />
        ) : undefined
      }
      resultSecondaryText={taxonomies}
      addressTitle={location.officeName}
      address={location.address}
      locationDistance={
        <DistanceChip distance={distance} theme={themeForDistance} language={language} />
      }
      primaryActionBtn={
        <div>
          <BookButtonBySdk
            npi={provider.npi}
            address={provider.locations[0].address}
            language={language}
            analyticsMetadata={{ ...analyticsMetadata, brokerId, queryId }}
            onBook={onBook}
            onBookClick={onBookClick}
            bgColor="themedOutline"
            buttonType="small"
          />
          <span
            data-qa-gender={provider.gender}
            data-qa-language={languagesStringArray}
            style={{ display: 'none' }}
          />
        </div>
      }
      secondaryActionBtn={
        profileUrl ? (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              window.open(profileUrl + extractUTMQueryParams(), '_blank');
              if (onProviderDetailsClick) {
                onProviderDetailsClick();
              }
            }}
            theme={theme}
            bgColor="themedOutline"
            buttonType="small"
          >
            {intl.formatMessage({ id: 'general.viewProfile' })}
          </Button>
        ) : undefined
      }
      verticalMode
      showLocation
      showShadow={false}
      showNewBookButton
    >
      <Availability
        acceptNewPatients={provider.acceptNewPatients || location.acceptNewPatients}
        size={AvailabilitySizeEnum.tiny}
        theme={theme}
        language={language}
      />
      <BookingHorizon language={language} bookingHorizonDays={bookingHorizonDays} theme={theme} />
    </ResultCardUI>
  );
};

export default ResultCard;
