import logger from '../utils/logger';

// eslint-disable-next-line import/prefer-default-export
export function sendLogToBackend(params: object) {
  const currentFullUrl = window.location.href;
  fetch('/log', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ currentFullUrl, params }),
  }).catch((e) => {
    logger.error(`Failed to send log to backend!`, { e });
  });
}
