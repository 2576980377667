import React, { FunctionComponent } from 'react';
import withIntl, { WithIntlProps, MessagesType } from '@getvim/components-hocs-with-intl';
import es from './translations/es.json';
import './BookingHorizonBadge.less';

type Bucket = {
  message: MessagesType;
  icon: string;
};

const daysBuckets: { maxDays: number; bucket: Bucket }[] = [
  {
    maxDays: 2,
    bucket: {
      message: {
        defaultMessage: 'Likely available within 2 days',
        id: 'bookingHorizon.likelyTwoDays',
      },
      icon: 'icon-hourglass-bottom',
    },
  },
  {
    maxDays: 7,
    bucket: {
      message: {
        defaultMessage: 'Likely available within a week',
        id: 'bookingHorizon.likelyInAWeek',
      },
      icon: 'icon-hourglass-middle',
    },
  },
  {
    maxDays: 14,
    bucket: {
      message: {
        defaultMessage: 'Likely available within two weeks',
        id: 'bookingHorizon.likelyInTwoWeeks',
      },
      icon: 'icon-hourglass-middle',
    },
  },
  {
    maxDays: 31,
    bucket: {
      message: {
        defaultMessage: 'Likely available within a month',
        id: 'bookingHorizon.likelyInAMonth',
      },
      icon: 'icon-hourglass-top',
    },
  },
];
const maxDaysAllowed = daysBuckets[daysBuckets.length - 1].maxDays;

const lastBucket: Bucket = {
  message: {
    defaultMessage: 'Likely unavailable within a month',
    id: 'bookingHorizon.likelyNotAvailableWithinMonth',
  },
  icon: 'icon-hourglass-top',
};

type BookingHorizonPropsWithoutIntl = {
  bookingHorizonDays?: number;
  theme: {
    secondaryColor: string,
  }
};
type BookingHorizonProps = BookingHorizonPropsWithoutIntl & WithIntlProps;
const BookingHorizonChip: FunctionComponent<BookingHorizonProps> = ({
                                                                      bookingHorizonDays,
                                                                      theme,
                                                                      intl,
                                                                    }) => {
  // weak-compare to null checks null & undefined, but still allows for 0
  if (bookingHorizonDays == null || bookingHorizonDays < 0) return null;

  const foundBucket =
    bookingHorizonDays <= maxDaysAllowed
      ? daysBuckets.find(bucket => bucket.maxDays >= bookingHorizonDays)?.bucket
      : null;

  const message = foundBucket ? foundBucket.message : lastBucket.message;
  const icon = foundBucket ? foundBucket.icon : lastBucket.icon;
  return (
    <div className="availability-item booking-horizon">
      <i className={icon} style={{ color: theme.secondaryColor }}/>
      {intl.formatMessage(message)}
    </div>
  );
};

export default withIntl<BookingHorizonPropsWithoutIntl>({ es })(BookingHorizonChip);
