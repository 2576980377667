import { array, string, type, number, any, TypeOf } from 'io-ts';
import { GenderEnum, LangEnum } from '@getvim/components-molecules-button-selectors';
import { GeoCode } from '../models/Geocode';
import { SearchResultsTypeValidator } from '../models/SearchResults';
import { Language } from '../models/Language';

export type FindRequest = {
  filters: {
    taxonomy: string[];
    gender?: Omit<GenderEnum, 'ANY'>;
    spokenLanguage?: Omit<LangEnum, 'ANY'>;
    sanitasPlus?: boolean;
    offerTelemedicine?: boolean;
    acceptNewPatients?: boolean;
    distance?: number;
  };
  queryId?: string;
  memberSessionId?: string;
  brokerId?: string | null;
  geo: Partial<GeoCode>;
  skip?: number;
  limit?: number;
  memberLanguage?: Language;
};

export const FindResponseV = type(
  {
    data: SearchResultsTypeValidator,
  },
  'FindResponse',
);
export type FindResponse = TypeOf<typeof FindResponseV>;

export const TaxonomiesResponseV = type(
  {
    data: array(
      type({
        codes: array(string),
        name: string,
      }),
    ),
  },
  'TaxonomiesResponse',
);
export type TaxonomiesResponse = TypeOf<typeof TaxonomiesResponseV>;

export const FreeTextResponseV = type({
  data: type({
    searchTerm: string,
    suggestions: type({
      provider: array(any),
      specialty: array(
        type({
          searchTerm: string,
          ranking: number,
          filters: array(
            type({
              type: string,
              value: type({
                taxonomyCode: string,
                taxonomyDisplayName: string,
              }),
            }),
          ),
        }),
      ),
    }),
  }),
});

export type FreeTextResponse = TypeOf<typeof FreeTextResponseV>;

export const LanguagesResponseV = type(
  {
    data: type({
      ids: array(string),
    }),
  },
  'LanguagesResponse',
);

export type LanguagesResponse = TypeOf<typeof LanguagesResponseV>;

export const ThemeResponseV = type({
  accentColor: string,
  buttonColor: string,
  className: string,
  font: string,
  fontWeight: string,
  fullName: string,
  linkColor: string,
  logo: string,
  mainColor: string,
  name: string,
  secondaryColor: string,
  secondaryColorLight: string,
  secondaryColorLighter: string,
  secondaryDarkColor: string,
  successColor: string,
  buttonBorderRadius: string,
});

export type ThemeResponse = TypeOf<typeof ThemeResponseV>;
