import React, { FunctionComponent } from 'react';
import LocationSelect from '@getvim/components-molecules-location-select';
import { useTheme } from '@getvim/components-hooks-use-theme';
import InputStyleWrapper, { InputStyle } from '@getvim/components-atoms-input-style-wrapper';
import Container, { ContainerWidth } from '@getvim/components-atoms-container';
import Text, { ColorNameEnum } from '@getvim/components-atoms-text';
import { EventCreatorType, TypeaheadEventType } from '@getvim/components-utils-analytics';
import { GeoCode, GeoCodeValidator } from '../../models/Geocode';
import { assertType } from '../../models/utils';
import SpecialtiesNameInput, { SpecialtyOrProviderEnterEventType } from '../SpecialtiesOrNameInput';
import { FreeTextTerm, FreeTextTermValidator } from '../../models/FreeText';
import { Language } from '../../models/Language';
import { ProviderType } from '../../models/Provider';
import SearchEvents from '../../utils/searchEvents';

export type IsGoogleLoaded = {
  isGoogleApiLoaded: boolean;
};

type TopFormProps = IsGoogleLoaded & {
  freeText: FreeTextTerm;
  geo: Partial<GeoCode>;
  language: Language;
  provider?: ProviderType;
  onChange: (
    update: { geo?: Partial<GeoCode>; freeText?: FreeTextTerm },
    analyticsEventCreator: EventCreatorType,
  ) => void;
  onTypeaheadEntered?: (
    event: TypeaheadEventType<SpecialtyOrProviderEnterEventType>,
    eventCreator: EventCreatorType,
  ) => void;
};

const TopForm: FunctionComponent<TopFormProps> = ({
  freeText,
  geo,
  language,
  provider,
  isGoogleApiLoaded,
  onChange,
  onTypeaheadEntered,
}) => {
  const theme = useTheme();
  return (
    <section className="top-form">
      <div className="location-and-specialty-part padding-box-30">
        <Container width={ContainerWidth.large}>
          <InputStyleWrapper inputStyle={InputStyle.pill} theme={theme}>
            <div className="location-select input left-inner-icon">
              <Text
                theme={theme}
                colorName={ColorNameEnum.themeSecondary}
                className="input-inner-icon"
              >
                <i className="icon-map-pin-e i-va-fix-2" />
              </Text>
              <LocationSelect
                geo={geo}
                onChange={(value) => {
                  assertType(value, GeoCodeValidator);
                  onChange(
                    {
                      geo: value || undefined,
                    },
                    (queryId, memberSessionId, brokerId) => [
                      SearchEvents.filterClick({
                        filterName: 'geo',
                        memberSessionId,
                        queryId,
                        brokerId,
                        filterValue: value || undefined,
                      }),
                    ],
                  );
                }}
                isGoogleApiLoaded={isGoogleApiLoaded}
              />
            </div>
            <SpecialtiesNameInput
              provider={provider}
              onChange={(value, eventCreator) => {
                assertType(value, FreeTextTermValidator);
                onChange(
                  {
                    freeText: value,
                  },
                  eventCreator,
                );
              }}
              selectedValue={freeText}
              theme={theme}
              geo={geo?.geocode}
              userLanguage={language}
              isDisabled={!geo}
              onTypeaheadEntered={onTypeaheadEntered}
            />
          </InputStyleWrapper>
        </Container>
      </div>
    </section>
  );
};

export default TopForm;
