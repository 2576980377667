import * as qs from 'qs';
import { useEffect } from 'react';

declare global {
  interface Window {
    messageHandlers: {
      [key: string]: any;
    };
    __INITIAL_STATE?: {
      allowedFrameDomain?: string;
    };
    [key: string]: any;
  }
}

// eslint-disable-next-line no-underscore-dangle
const allowedFrameDomain = window.__INITIAL_STATE?.allowedFrameDomain || '*';

enum SDKTypes {
  ios = 'ios',
  android = 'android',
  browser = 'browser',
}

const postMessageHandler = {
  [SDKTypes.ios]: (message: object) => {
    window.messageHandlers.vimSelectProviderMessageHandler.postMessage(message);
  },
  [SDKTypes.android]: (message: object) => {
    // Android support passing only primitive types, and only two parameters
    window.vimSelectProviderMessageHandler.postMessage(JSON.stringify(message));
  },
  [SDKTypes.browser]: (message: object) => {
    window.parent.postMessage(message, allowedFrameDomain);
  },
};

const sdkEvent = (event: string, withPayload = false, preCallback?: (payload: any) => any) => (
  payload?: any,
) => {
  if (preCallback) preCallback(payload);
  const data = {
    event,
    payload: withPayload ? payload : null,
  };
  const query = qs.parse(window.location.search);
  const sdkType: SDKTypes = (query?.sdkType as SDKTypes) || SDKTypes.browser;
  const postMessage = postMessageHandler[sdkType];
  postMessage(data);
};

type knownEvents = {
  selected: any;
  error: any;
  book: any;
  booked: any;
  finished: any;
  closed: any;
};

type PreEventCallbackType = { [key in keyof knownEvents]?: (payload?: any) => any };

let callbacks: PreEventCallbackType = {};

export function initPreEventCallback(preEventCallback: PreEventCallbackType) {
  callbacks = preEventCallback;
}

const useSdkEvents = (paramUpdateHandler?: (payload: any) => void) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (paramUpdateHandler) {
      const onMessage = (message: MessageEvent) => {
        if (message.isTrusted && message.data.event === 'onUpdateParams') {
          paramUpdateHandler(message.data.payload);
        }
      };
      window.addEventListener('message', onMessage);
      if (window.parent !== window) sdkEvent('load')();
      return () => window.removeEventListener('message', onMessage);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return {
    selected: sdkEvent('selected', true, callbacks?.selected),
    error: sdkEvent('error', true, callbacks?.error),
    book: sdkEvent('bookFromSearch', true, callbacks?.book),
    booked: sdkEvent('booked', true, callbacks?.booked),
    finished: sdkEvent('finished', true, callbacks?.finished),
    closed: sdkEvent('closed', false, callbacks?.closed),
  };
};

export default useSdkEvents;
