import { defineMessages } from 'react-intl';

export const BookingHorizonMessagesFullMessages = defineMessages({
  sameDay: {
    defaultMessage: 'Same day appointments',
    id: 'enum.providerWaitTime.sameDayLong',
  },
  sameWeek: {
    defaultMessage: 'Same week appointments',
    id: 'enum.providerWaitTime.sameWeekLong',
  },
  sameMonth: {
    defaultMessage: 'In a month',
    id: 'enum.providerWaitTime.SameMonthLong',
  },
  overAMonth: {
    defaultMessage: 'Over a month',
    id: 'enum.providerWaitTime.overAMonthLong',
  },
  notAccepting: {
    defaultMessage: 'Not accepting',
    id: 'enum.providerWaitTime.notAcceptingLong'
  },
});

export const BookingHorizonMessagesShortMessages = defineMessages({
  sameDay: {
    defaultMessage: 'Same day',
    id: 'enum.providerWaitTime.sameDay'
  },
  sameWeek: {
    defaultMessage: 'Same week',
    id: 'enum.providerWaitTime.sameWeek'
  },
  sameMonth: {
    defaultMessage: 'In a month',
    id: 'enum.providerWaitTime.SameMonth'
  },
  overAMonth: {
    defaultMessage: 'Over a month',
    id: 'enum.providerWaitTime.overAMonth'
  },
  notAccepting: {
    defaultMessage: 'Not accepting',
    id: 'enum.providerWaitTime.notAccepting'
  },
});