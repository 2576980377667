import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SvgVimLoader from '@getvim/components-atoms-svgs/build/vimLoader';
import SvgVimHeart from '@getvim/components-atoms-svgs/build/vimHeart';
import SvgCalendarLoader from '@getvim/components-atoms-svgs/build/calendarLoader';
import './Loader.less';

const loaderType = {
  default: 'vim-loader',
  dots: 'dots-loader',
  calendar: 'calendar-loader',
};

const loaderSize = {
  default: '',
  small: 'small',
};

const loaderColor = {
  default: '',
  grey: 'grey',
  white: 'white',
};

const loaderPadding = {
  default: 'padding-v-50',
  small: 'padding-box-10',
  none: 'no-padding',
};

type LoaderProps = {
  theme?: { mainColor: string },
  className?: string,
  type?: keyof typeof loaderType,
  size?: keyof typeof loaderSize,
  color?: keyof typeof loaderColor,
  inline?: string,
  padding?: keyof typeof loaderPadding,
  ariaLiveOff?: string,
  ariaLabel?: string
}

function Loader({ className, type, size, color, theme, inline, padding, ariaLiveOff, ariaLabel }: LoaderProps) {
  const dynamicStyle = color === 'default' ? { backgroundColor: theme?.mainColor } : {};
  const loaderInline = inline ? 'inline-block' : undefined;
  const ariaLive = ariaLiveOff ? 'off' : 'polite';
  return (
    <div
      className={classNames(
        'loader',
        className,
        loaderType[type || 'default'],
        loaderSize[size || 'default'],
        loaderColor[color || 'default'],
        loaderInline,
        loaderPadding[padding || 'default'],
      )}
      aria-live={ariaLive}
      aria-label={ariaLabel}
    >
      <div className="bounce bounce1" style={dynamicStyle}/>
      <div className="bounce bounce2" style={dynamicStyle}/>
      <div className="bounce bounce3" style={dynamicStyle}/>
      <span className="svg-loader">
        <SvgVimLoader/>
        <SvgVimHeart/>
      </span>
      <span className="calendar-loader-wrap">
        <SvgCalendarLoader />
      </span>   
    </div>
  );
}

Loader.propTypes = {
  theme: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  padding: PropTypes.string,
  inline: PropTypes.bool,
  ariaLiveOff: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

Loader.defaultProps = {
  theme: undefined,
  className: '',
  type: 'default',
  size: 'default',
  color: 'default',
  padding: 'default',
  inline: false,
  ariaLiveOff: false,
  ariaLabel: 'Loading',
};

export default Loader;
