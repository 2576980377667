import React, { FunctionComponent } from 'react';
import { defineMessages } from 'react-intl';
import es from './translations/es.json';
import withIntl, { WithIntlProps } from '@getvim/components-hocs-with-intl';

const messages = defineMessages({
  acceptingNewPatients: {
    defaultMessage: 'Accepting new patients',
    id: 'acceptNewPatients.acceptingNewPatients',
  },
});

type AcceptNewPatientsPropsType = {
  acceptNewPatients: boolean;
  textClass?: string;
  itemClass?: string;
  theme: {
    mainColor: string;
    secondaryColor: string;
  };
  acceptNewPatientsIcon?: JSX.Element;
};

const AcceptNewPatients: FunctionComponent<AcceptNewPatientsPropsType & WithIntlProps> = ({
  acceptNewPatients,
  acceptNewPatientsIcon,
  textClass,
  itemClass,
  theme,
  intl,
}) => {
  if (!acceptNewPatients) return null;
  return (
    <div className={itemClass || undefined}>
      {acceptNewPatientsIcon || <i className="icon-door-f" />}
      <div className={textClass || undefined}>
        {intl.formatMessage(messages.acceptingNewPatients)}
      </div>
    </div>
  );
};

export default withIntl<AcceptNewPatientsPropsType>({
  es,
})(AcceptNewPatients);
