import React, { Context, FunctionComponent, useContext } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes, { InferProps } from 'prop-types';

export const ThemeProps = {
  className: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  font: PropTypes.string.isRequired,
  fontWeight: PropTypes.string.isRequired,
  mainColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  secondaryDarkColor: PropTypes.string.isRequired,
  secondaryColorLight: PropTypes.string.isRequired,
  secondaryColorLighter: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  accentColor: PropTypes.string.isRequired,
  linkColor: PropTypes.string.isRequired,
  successColor: PropTypes.string.isRequired,
  buttonBorderRadius: PropTypes.string.isRequired,
};

export const ThemeShapeProps = PropTypes.shape(ThemeProps);

export type ThemePropsType = InferProps<typeof ThemeProps>;

export const themes = {
  premera: {
    className: 'premera',
    fullName: 'premera',
    logo: '//static.getvim.com/img/logos/premeraLogo2.png',
    font: 'loraregular , Helvetica, Arial, sans-serif',
    fontWeight: 'normal',
    mainColor: '#313234',
    secondaryColor: '#117bab',
    secondaryDarkColor: '#117bab',
    secondaryColorLight: '#e1eaf1',
    secondaryColorLighter: '#f2f2f9',
    buttonColor: '#117bab',
    accentColor: '#e04e39',
    linkColor: '#117bab',
    successColor: '#278600',
    buttonBorderRadius: '4px',
  },
  sanitas: {
    className: 'sanitas',
    fullName: 'sanitas medical center',
    logo: '//static.getvim.com/img/logos/sanitasLogo.png',
    font: 'proximaNova , Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
    mainColor: '#002E58',
    secondaryColor: '#7ba0c6',
    secondaryDarkColor: '#0071B8',
    secondaryColorLight: '#86a9cc',
    secondaryColorLighter: '#dae5f0',
    buttonColor: '#00B56C',
    accentColor: '#F63277',
    linkColor: '#1477D7',
    successColor: '#00B56C',
    buttonBorderRadius: '4px',
  },
  sanitasBlueTheme: {
    className: 'sanitas',
    fullName: 'sanitas medical center',
    logo: '//static.getvim.com/img/logos/sanitasLogo.png',
    font: 'proximaNova, Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
    mainColor: '#045393',
    secondaryColor: '#48C85A',
    secondaryDarkColor: '#32333C',
    secondaryColorLight: '#e1eaf1',
    secondaryColorLighter: '#f2f2f9',
    buttonColor: '#045393',
    accentColor: '#FA7071',
    linkColor: '#045393',
    successColor: '#48C85A',
    buttonBorderRadius: '4px',
  },
  allsavers: {
    className: 'uhc',
    fullName: 'united healthcare',
    logo: '//static.getvim.com/img/logos/uhcLogo.png',
    font: 'proximaNova , Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
    mainColor: '#083a6b',
    secondaryColor: '#0071b8',
    secondaryDarkColor: '#0071b8',
    secondaryColorLight: '#e1eaf1',
    secondaryColorLighter: '#f2f2f9',
    buttonColor: '#278600',
    accentColor: '#e74476',
    linkColor: '#0071b8',
    successColor: '#278600',
    buttonBorderRadius: '4px',
  },
  vim: {
    className: 'vim',
    fullName: 'vim',
    logo: '//static.getvim.com/img/logos/vimLogoMdTM.png',
    font: 'proximaNova , Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
    mainColor: '#083a6b',
    secondaryColor: '#09ace4',
    secondaryDarkColor: '#09ace4',
    secondaryColorLight: '#bfdce6',
    secondaryColorLighter: '#d9eaf1',
    buttonColor: '#083a6b',
    accentColor: '#e74476',
    linkColor: '#09ace4',
    successColor: '#278600',
    buttonBorderRadius: '4px',
  },
  walgreens: {
    className: 'walgreens',
    fullName: 'walgreens',
    logo: '//static.getvim.com/img/logos/walgreensLogo.png',
    font: 'Source Sans Pro',
    fontWeight: 'bold',
    mainColor: '#323264',
    secondaryColor: '#e62600',
    secondaryDarkColor: '#e1f4ff',
    secondaryColorLight: '#F5F5F0',
    secondaryColorLighter: '#F5F5EE',
    buttonColor: '#323264',
    accentColor: '#FEC4CE',
    linkColor: '#323264',
    successColor: '#278600',
    buttonBorderRadius: '50px',
  },
  floridaBlue: {
    className: 'fb',
    fullName: 'florida blue',
    logo: '//static.getvim.com/img/logos/floridaBlueLogo.png',
    font: 'proximaNova , Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
    mainColor: '#0f5499',
    secondaryColor: '#0091cb',
    secondaryDarkColor: '#0071B8',
    secondaryColorLight: '#e1eaf1',
    secondaryColorLighter: '#f2f2f9',
    buttonColor: '#43b02a',
    accentColor: '#E74476',
    linkColor: '#1477D7',
    successColor: '#43b02a',
    buttonBorderRadius: '4px',
  },
};

let currentThemeContext: Context<ThemePropsType> = React.createContext(themes.vim);

export const ThemeContext = (theme: ThemePropsType = themes.vim) => {
  currentThemeContext = React.createContext(theme);
  return currentThemeContext;
};

export const useTheme = () => useContext(currentThemeContext);

type ThemeVariablesWrapperType = FunctionComponent<{ theme: ThemePropsType }>;
export const ThemeVariablesWrapper: ThemeVariablesWrapperType = ({ theme, children }) => {
  const themeCssVariables = Object.keys(theme).reduce<any>((acc, themePascalCase) => {
    const themeKebabCase = themePascalCase.replace(/([A-Z])/g, '-$1').toLowerCase();
    const themeKeyAsVariable = `--${themeKebabCase}`;
    if (themePascalCase === 'logo') {
      acc += `${themeKeyAsVariable}: url("${theme[themePascalCase as keyof ThemePropsType]}");`;
    } else {
      acc += `${themeKeyAsVariable}: ${theme[themePascalCase as keyof ThemePropsType]};`;
    }
    return acc;
  }, ``);

  const variablesAsStyle = `
  :root {
    ${themeCssVariables}
  }
  `;

  return (
    <div id="theme-css-variables-wrapper">
      <Helmet>
        <style>{variablesAsStyle}</style>
      </Helmet>
      {children}
    </div>
  );
};
