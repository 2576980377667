import React, { FunctionComponent, useEffect, useState } from 'react';
import useQueryString from '@getvim/components-hooks-use-query-string';
import { useTheme } from '@getvim/components-hooks-use-theme';
import BookButton, { BookButtonPropsType, BookingType } from '@getvim/components-atoms-book-button';
import VimSdkClient from '@getvim/utils-vim-sdk-client';
import { Language } from '../../models/Language';
import { NonEmptyString } from '../../models/utils';
import logger from '../../utils/logger';
import { getSessionId } from '../../api/tokensStorage';

export type OnBook = {
  onBook?: (payload: {
    address: string;
    npi: string;
    appointmentId?: string;
    bookingType?: BookingType;
  }) => void;
};

export type BookButtonBySdkProps = OnBook & {
  npi: string;
  address: string;
  btnClassName?: string;
  scheduleButton?: boolean;
  language: Language;
  analyticsMetadata: {
    queryId: string;
    brokerId?: NonEmptyString;
    [key: string]: any;
  };
  onBookClick?: (params: { npi: string; address: string }) => void;
} & Partial<BookButtonPropsType>;

const BookButtonBySdk: FunctionComponent<BookButtonBySdkProps> = ({
  npi,
  address,
  btnClassName,
  scheduleButton = false,
  language,
  onBook,
  onBookClick,
  analyticsMetadata,
  ...rest
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [bookingType, setBookingType] = useState<BookingType>(BookingType.NONE);
  const { memberToken: memberTokenFromQS } = useQueryString();
  const memberToken = memberTokenFromQS ? (memberTokenFromQS as string) : undefined;

  useEffect(() => {
    const getAvailability = async () => {
      const availability = await VimSdkClient.getProviderAvailability(
        [
          {
            npi,
            address,
          },
        ],
        {
          memberToken,
        },
      ).catch((error) => {
        logger.error(error.message);

        return null;
      });
      setBookingType(availability ? BookingType[availability[0].bookingType] : BookingType.NONE);
      setIsLoading(false);
    };

    getAvailability();
  }, [npi, address, memberToken]);

  return isLoading ? null : (
    <BookButton
      btnClassName={btnClassName}
      bookingType={bookingType}
      scheduleButton={scheduleButton}
      isLoading={isLoading}
      onClick={() => {
        if (onBookClick) onBookClick({ npi, address });

        VimSdkClient.showBookingDialog(npi, address, {
          memberToken,
          locale: language,
          metadata: { ...analyticsMetadata, memberSessionId: getSessionId() },
        })
          .then((appointmentId) => {
            if (onBook) {
              onBook({ npi, address, bookingType, appointmentId });
            }
          })
          .catch((error) => logger.error(error.message));
      }}
      theme={theme}
      language={language}
      {...rest}
    />
  );
};

export default BookButtonBySdk;
