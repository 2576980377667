import React, { FunctionComponent } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import AcceptNewPatients from '@getvim/components-molecules-accept-new-patients';
import BookingHorizonChip, {
  BookingHorizonEnum,
} from '@getvim/components-atoms-booking-horizon-chip';

import { WithLanguageProps } from '@getvim/components-hocs-with-intl';

import './Availability.less';

export enum AvailabilitySizeEnum {
  large = 'large',
  small = 'small',
  tiny = 'tiny',
}

const AvailabilityProps = {
  acceptNewPatients: PropTypes.bool,
  bookingHorizon: PropTypes.oneOf(Object.values(BookingHorizonEnum)),
  bookingHorizonClassName: PropTypes.string,
  acceptNewPatientsIcon: PropTypes.element,
  card: PropTypes.bool,
  shortText: PropTypes.bool,
  showBg: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(AvailabilitySizeEnum)),
  theme: PropTypes.shape({
    mainColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
  }).isRequired,
};

type AvailabilityPropsType = InferProps<typeof AvailabilityProps>;

const AvailabilitySection = ({
  acceptNewPatients,
  acceptNewPatientsIcon,
  bookingHorizon,
  bookingHorizonClassName,
  shortText,
  theme,
  language,
}: AvailabilityPropsType & WithLanguageProps) => (
  <>
    {acceptNewPatients && (
      <AcceptNewPatients
        language={language}
        acceptNewPatients={acceptNewPatients}
        textClass="item-text"
        itemClass="availability-item new-patients"
        theme={theme}
        acceptNewPatientsIcon={acceptNewPatientsIcon!}
      />
    )}
    {bookingHorizon && (
      <BookingHorizonChip
        language={language}
        bookingHorizon={bookingHorizon}
        className={bookingHorizonClassName}
        shortText={shortText}
        theme={theme}
      />
    )}
  </>
);

const Availability: FunctionComponent<AvailabilityPropsType & WithLanguageProps> = ({
  acceptNewPatients,
  acceptNewPatientsIcon,
  bookingHorizon,
  bookingHorizonClassName,
  card,
  shortText,
  size,
  theme,
  language,
  showBg,
}) => {
  if (!bookingHorizon && !acceptNewPatients) return null;
  return (
    <div className={classNames('availability-section', size, { 'show-bg': showBg })}>
      {card ? (
        <div className="card">
          <div className="card-body">
            <AvailabilitySection
              language={language}
              acceptNewPatients={acceptNewPatients}
              bookingHorizon={bookingHorizon}
              bookingHorizonClassName={bookingHorizonClassName}
              shortText={shortText}
              theme={theme}
              acceptNewPatientsIcon={acceptNewPatientsIcon}
            />
          </div>
        </div>
      ) : (
        <AvailabilitySection
          language={language}
          acceptNewPatients={acceptNewPatients}
          bookingHorizon={bookingHorizon}
          bookingHorizonClassName={bookingHorizonClassName}
          shortText={shortText}
          theme={theme}
          acceptNewPatientsIcon={acceptNewPatientsIcon}
        />
      )}
    </div>
  );
};

Availability.propTypes = AvailabilityProps;
Availability.defaultProps = {
  bookingHorizonClassName: '',
  card: false,
  shortText: false,
  size: undefined,
  showBg: false,
};

export default Availability;
