import React from 'react';
import Text, { ColorNameEnum } from '@getvim/components-atoms-text';
import { useTheme } from '@getvim/components-hooks-use-theme';

const IconOrError = ({
  errorMessage,
  icon,
}: {
  errorMessage: string | undefined;
  icon: string;
}) => {
  const theme = useTheme();

  return (
    <Text
      theme={theme}
      colorName={errorMessage ? ColorNameEnum.danger : ColorNameEnum.themeSecondary}
      className="input-inner-icon"
    >
      <i className={errorMessage ? 'icon-x text-14' : `${icon} i-va-fix-2`} />
    </Text>
  );
};

export default IconOrError;
