import { useEffect, useState } from 'react';

const getWidth = () =>
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const useVerticalMode = (verticalWidth = 1400) => {
  const [verticalMode, setVerticalMode] = useState(getWidth() <= verticalWidth);

  useEffect(() => {
    const resizeListener = () => {
      setVerticalMode(getWidth() <= verticalWidth);
    };

    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, [verticalWidth]);

  return verticalMode;
};

export default useVerticalMode;
