import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'fast-text-encoding/text';
import React from 'react';
import ReactDOM from 'react-dom';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/es';
import { BrowserRouter as Router } from 'react-router-dom';
import CoreProviders from './core/CoreProviders';
import Routes from './routes/Routes';
import loadAccessibility from './accessibility';

loadAccessibility();

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <CoreProviders>
        <Routes />
      </CoreProviders>
    </React.StrictMode>
  </Router>,
  document.getElementById('root'),
);
