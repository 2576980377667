import { useTheme } from '@getvim/components-hooks-use-theme';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from '@getvim/translate';
import { useFormik } from 'formik';
import { withAnalyticsProp } from '@getvim/components-utils-analytics';
import LocationSelect from '@getvim/components-molecules-location-select';
import InputStyleWrapper, { InputStyle } from '@getvim/components-atoms-input-style-wrapper';
import BrandedHeading from '@getvim/components-atoms-branded-heading';
import Button from '@getvim/components-atoms-button';
import Container, { ContainerWidth } from '@getvim/components-atoms-container';
import * as Yup from 'yup';
import SpecialtiesNameInput from '../../components/SpecialtiesOrNameInput';
import { FreeTextTerm, TypeaheadTypes } from '../../models/FreeText';
import { GeoCode, GeoCodeValidator } from '../../models/Geocode';
import { assertType } from '../../models/utils';
import usePageNav from '../../hooks/usePageNav';
import { withUserProp } from '../../models/User';
import IconOrError from '../../utils/iconOrError';
import SearchEvents from '../../utils/searchEvents';

import './LandingPage.less';
import { getBrokerId, saveBrokerId } from '../../utils/brokerIdStorage';
import { getSessionId } from '../../api/tokensStorage';

export type IsGoogleLoaded = {
  isGoogleApiLoaded: boolean;
};
export type SearchPageProps = IsGoogleLoaded &
  withUserProp &
  withAnalyticsProp & { memberToken?: string };

export type LandingPageFormValues = {
  location?: Partial<GeoCode>;
  freeText?: FreeTextTerm;
  brokerId?: string;
};

const LandingPage: FunctionComponent<SearchPageProps> = ({
  analytics,
  user,
  memberToken,
  isGoogleApiLoaded,
}) => {
  const navToPage = usePageNav();
  const theme = useTheme();
  const intl = useIntl();

  const [brokerIdRef, setBrokerIdRef] = useState<any>(null);
  const [locationSelectRef, setLocationSelectRef] = useState<any>(null);
  const [specialtyOrNameRef, setSpecialtyOrNameRef] = useState<any>(null);

  const initialFormValues: LandingPageFormValues = {
    location: user?.geo ? { geocode: user.geo } : undefined,
    brokerId: (user.isBroker && getBrokerId()) || undefined,
  };

  const validationSchema = Yup.object()
    .shape({
      freeText: Yup.object().required(intl.formatMessage({ id: 'general.specialtyOrName' })),
      location: Yup.object().required(
        intl.formatMessage({ id: 'pages.landingPage.selectLocation' }),
      ),
      brokerId: user.isBroker
        ? Yup.string().required(intl.formatMessage({ id: 'pages.landingPage.enterBrokerId' }))
        : Yup.string().notRequired(),
    })
    .required();

  useEffect(() => {
    const { eventName, params } = SearchEvents.startMemberSession({
      memberSessionId: getSessionId(),
      brokerId: getBrokerId() || undefined,
    });

    analytics.track(eventName, params);
  }, [analytics]);

  useEffect(() => {
    if (user.isBroker) brokerIdRef?.focus();
    else locationSelectRef?.focus();
  }, [brokerIdRef, locationSelectRef, user.isBroker]);

  const submitForm = (values: LandingPageFormValues, validateForm: () => void) => {
    const { freeText, location: geo, brokerId } = values;

    if (brokerId) saveBrokerId(brokerId);

    if (freeText && geo) {
      const { type } = freeText;
      if (type === TypeaheadTypes.provider) {
        navToPage({
          page: 'ProviderDetails',
          params: {
            freeText,
            geo,
            brokerId,
            user,
            analyticsMetadata: analytics.metadata,
            memberToken,
          },
        });
      } else {
        navToPage({
          page: 'MainSearch',
          params: {
            freeText,
            geo,
            brokerId,
            user,
            analyticsMetadata: analytics.metadata,
            memberToken,
          },
        });
      }
    } else {
      validateForm();
    }
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    onSubmit: (values, { validateForm }) => submitForm(values, validateForm),
    validateOnChange: false,
    validationSchema,
  });

  useEffect(() => {
    if (!formik.isValid && formik.submitCount > 0) {
      if (formik.errors.brokerId) brokerIdRef?.focus();
      else if (formik.errors.location) locationSelectRef.focus();
      else specialtyOrNameRef.focus();
    }
  }, [
    formik.submitCount,
    formik.isValid,
    formik.errors,
    brokerIdRef,
    locationSelectRef,
    specialtyOrNameRef,
  ]);
  return (
    <>
      <header className="landing-page-header">
        <BrandedHeading
          text={intl.formatMessage({ id: 'pages.landingPage.letsFindYouAProvider' })}
          theme={theme}
          size="large"
          className="landing-page-main-title padding-h-30"
        />
      </header>
      <form onSubmit={formik.handleSubmit} className="landing-page-form">
        <Container width={ContainerWidth.large}>
          <div>
            <InputStyleWrapper
              inputStyle={InputStyle.pill}
              theme={theme}
              hasError={!formik.isValid}
            >
              {user.isBroker && (
                <div className="broker-id-input-wrapperinput input left-inner-icon">
                  <IconOrError errorMessage={formik.errors.brokerId} icon="icon-shield-check" />
                  <input
                    type="text"
                    id="brokerId"
                    name="brokerId"
                    onChange={(value) => formik.setFieldValue('brokerId', value.target.value)}
                    value={formik.values.brokerId}
                    placeholder={
                      formik.errors.brokerId || intl.formatMessage({ id: 'general.brokerId' })
                    }
                    ref={setBrokerIdRef}
                  />
                </div>
              )}
              <div className="location-select input left-inner-icon">
                <IconOrError errorMessage={formik.errors.location} icon="icon-map-pin-e" />
                <LocationSelect
                  geo={formik.values.location}
                  onChange={(value) => {
                    assertType(value, GeoCodeValidator);
                    const { eventName, params } = SearchEvents.filterClick({
                      filterName: 'geo',
                      memberSessionId: getSessionId(),
                      queryId: null,
                      brokerId: getBrokerId(),
                      filterValue: value || undefined,
                    });

                    analytics.track(eventName, params);

                    formik.setValues({ ...formik.values, location: value || undefined });
                  }}
                  isGoogleApiLoaded={isGoogleApiLoaded}
                  className="location-select"
                  placeholder={
                    formik.errors.location ||
                    intl.formatMessage({ id: 'pages.landingPage.enterYourLocation' })
                  }
                  inputRef={setLocationSelectRef}
                  onSubmit={formik.handleSubmit}
                />
              </div>
              <SpecialtiesNameInput
                onChange={(value, analyticsEventCreator) => {
                  const events = analyticsEventCreator(null, getSessionId(), getBrokerId());
                  for (const currEvent of events) {
                    const { eventName, params } = currEvent;
                    analytics.track(eventName, params);
                  }
                  formik.setFieldValue('freeText', value);
                }}
                selectedValue={formik.values.freeText}
                theme={theme}
                geo={formik.values.location?.geocode}
                isDisabled={!formik.values.location}
                userLanguage={user.language}
                errorMessage={formik.errors.freeText}
                inputRef={setSpecialtyOrNameRef}
              />
              <div className="submitButton input-button">
                <Button theme={theme} width="small" type="submit">
                  <FormattedMessage id="general.search" />
                </Button>
              </div>
            </InputStyleWrapper>
          </div>
        </Container>
      </form>
      <footer style={{ backgroundColor: theme.mainColor }} className="landing-page-footer" />
    </>
  );
};

export default LandingPage;
