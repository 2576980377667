import React, { FunctionComponent } from 'react';
import { useIntl, FormattedMessage } from '@getvim/translate';
import { useTheme } from '@getvim/components-hooks-use-theme';
import Text, {
  AlignEnum,
  ColorNameEnum,
  SizeEnum,
  WeightEnum,
} from '@getvim/components-atoms-text';
import Container, { ContainerWidth } from '@getvim/components-atoms-container';
import Pagination from '@getvim/components-molecules-pagination';
import SvgNoResultsX from '@getvim/components-atoms-svgs/build/noResultsX';
import { SearchResultsType } from '../../models/SearchResults';
import { Language } from '../../models/Language';
import { NonEmptyString } from '../../models/utils';
import { OnBook } from '../bookButtonBySdk/BookButtonBySdk';
import ResultCard from '../resultCard/ResultCard';
import { searchActionAnalytics } from '../../api';

import './Pagination.less';
import './SearchResults.less';
import logger from '../../utils/logger';

const maxPages = 10;

export type SearchResultsProps = OnBook & {
  searchResults: SearchResultsType;
  resultsLimit: number;
  pageNumber: number;
  queryId: string;
  language: Language;
  brokerId?: NonEmptyString;
  onChangePage: (p: number) => void;
  onBookClick: (params: { npi: string; address: string; ranking?: number }) => void;
  analyticsMetadata?: object;
};

const SearchResults: FunctionComponent<SearchResultsProps> = ({
  searchResults,
  resultsLimit,
  pageNumber,
  queryId,
  language,
  brokerId,
  onChangePage,
  onBook,
  onBookClick,
  analyticsMetadata,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  return !searchResults.providers.length ? (
    <div className="no-results text-center margin-top-50">
      <Container width={ContainerWidth.small}>
        <Text
          weight={WeightEnum.bold}
          className="margin-top-30"
          size={SizeEnum['18px']}
          colorName={ColorNameEnum.theme}
          theme={theme}
        >
          <FormattedMessage id="general.sorry" />
        </Text>
        <Text
          size={SizeEnum['14px']}
          colorName={ColorNameEnum.extraDark}
          className="margin-top"
          theme={theme}
        >
          <FormattedMessage id="components.searchResults.noProvidersFound" />
        </Text>
        <Text size={SizeEnum['14px']} colorName={ColorNameEnum.extraDark} theme={theme}>
          <FormattedMessage id="components.searchResults.tryAgain" />
        </Text>
        <div className="margin-top-30">
          <SvgNoResultsX theme={theme} />
        </div>
      </Container>
    </div>
  ) : (
    <div className="provider-discovery-results padding-box-30">
      <Text align={AlignEnum.left} theme={theme}>
        {searchResults.total === 1
          ? intl.formatMessage({ id: 'components.searchResults.oneSearchResult' })
          : intl.formatMessage(
              { id: 'components.searchResults.totalSearchResults' },
              {
                total: searchResults.total,
              },
            )}
      </Text>
      {searchResults.providers.map((provider) => {
        return (
          <ResultCard
            key={provider.npi}
            provider={provider}
            language={language}
            queryId={queryId}
            brokerId={brokerId}
            analyticsMetadata={analyticsMetadata}
            onBook={({ appointmentId, bookingType, ...payload }) => {
              const [location] = provider.locations;
              searchActionAnalytics({
                actionType: bookingType!,
                npi: provider.npi,
                locationId: location.id,
                queryId,
                ranking: provider.ranking!,
                entityId: appointmentId,
              }).then(() => {
                if (onBook) {
                  onBook(payload);
                }
              });
            }}
            onProviderDetailsClick={() => {
              const [location] = provider.locations;
              searchActionAnalytics({
                actionType: 'DETAILS',
                npi: provider.npi,
                locationId: location.id,
                queryId,
                ranking: provider.ranking!,
              }).catch((e) => {
                logger.error(`Failed to send "details" analytics!`, { e });
              });
            }}
            onBookClick={({ npi, address }) =>
              onBookClick({ npi, address, ranking: provider.ranking })
            }
          />
        );
      })}
      <div className="margin-top-20">
        <Pagination
          active={pageNumber}
          maxButtons={3}
          pagesNumber={Math.min(Math.ceil(searchResults.total / resultsLimit), maxPages)}
          onClick={onChangePage}
          className="vim-pagination"
        />
      </div>
    </div>
  );
};

export default SearchResults;
