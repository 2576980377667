import { BaseSearchEvent, EventResult } from './analyticsTypes';

const StartMemberSession = 'start member session';
const ViewProfilePage = 'view profile page';
const NoResults = 'viewed no results found';
const Results = 'viewed results found';
const ChangePage = 'change page search results';
const MoreInfoClick = 'view More Info';
const PhoneClick = 'click Phone';
const ViewAppointments = 'view appointments page';
const CancelAppointment = 'cancel appointment';
const RescheduleAppointment = 'reschedule appointment';

const SearchEvents = {
  startMemberSession({
    memberSessionId,
    brokerId,
    queryId,
  }: {
    memberSessionId: string;
    brokerId?: string;
    queryId?: string;
  }): EventResult {
    return {
      eventName: StartMemberSession,
      params: { memberSessionId, brokerId, queryId },
    };
  },
  viewProfilePage({
    queryId,
    memberSessionId,
    brokerId,
    profileType,
  }: { profileType: string } & BaseSearchEvent): EventResult {
    return {
      eventName: ViewProfilePage,
      params: { queryId, memberSessionId, brokerId, profileType },
    };
  },
  noResults({ queryId, memberSessionId, brokerId }: BaseSearchEvent): EventResult {
    return { eventName: NoResults, params: { queryId, memberSessionId, brokerId } };
  },
  results({ queryId, memberSessionId, brokerId }: BaseSearchEvent): EventResult {
    return { eventName: Results, params: { queryId, memberSessionId, brokerId } };
  },
  moreInfoClick({ queryId, memberSessionId, brokerId }: BaseSearchEvent): EventResult {
    return { eventName: MoreInfoClick, params: { queryId, memberSessionId, brokerId } };
  },
  viewAppointments({ queryId, memberSessionId, brokerId }: BaseSearchEvent): EventResult {
    return { eventName: ViewAppointments, params: { queryId, memberSessionId, brokerId } };
  },
  cancelAppointment({
    queryId,
    memberSessionId,
    brokerId,
    type,
  }: BaseSearchEvent & {
    type: string;
  }): EventResult {
    return { eventName: CancelAppointment, params: { queryId, memberSessionId, brokerId, type } };
  },
  rescheduleAppointment({ queryId, memberSessionId, brokerId }: BaseSearchEvent): EventResult {
    return { eventName: RescheduleAppointment, params: { queryId, memberSessionId, brokerId } };
  },
  phoneClick({
    queryId,
    memberSessionId,
    brokerId,
    type,
  }: { type: string } & BaseSearchEvent): EventResult {
    return { eventName: PhoneClick, params: { queryId, memberSessionId, brokerId, type } };
  },
  getFilterClick: <T>(
    filterNameToEvent: { [key in keyof T]: string },
    filtersToOmits: Array<keyof T>,
  ) => ({
    filterName,
    filterValue,
    memberSessionId,
    queryId,
    brokerId,
  }: {
    filterName: keyof T;
    filterValue: any;
    memberSessionId?: string | null;
    queryId?: string | null;
    brokerId?: string | null;
  }): EventResult => {
    return {
      eventName: filterNameToEvent[filterName]!,
      params: {
        memberSessionId,
        queryId,
        value: filtersToOmits.includes(filterName) ? undefined : filterValue,
        brokerId,
      },
    };
  },
  changePage({
    pageNumber,
    prevPage,
    isLastPage,
    queryId,
    memberSessionId,
    brokerId,
  }: BaseSearchEvent & {
    pageNumber: number;
    prevPage: number;
    isLastPage?: boolean;
  }): EventResult {
    return {
      eventName: ChangePage,
      params: { pageNumber, prevPage, isLastPage, queryId, memberSessionId, brokerId },
    };
  },
};

export function getSearchEvents<T = any>(
  filterNameToEventName: { [key in keyof T]: string },
  filtersToOmits?: Array<keyof T>,
) {
  const { getFilterClick, ...otherEvents } = SearchEvents;
  return {
    ...otherEvents,
    filterClick: getFilterClick<T>(filterNameToEventName, filtersToOmits || []),
  };
}
